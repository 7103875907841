import * as React from 'react'
import {
    Map as MapGLMap,
    MapRef,
    AttributionControl,
    MapLayerMouseEvent,
    MapSourceDataEvent,
    ErrorEvent,
} from 'react-map-gl'
import VisualizationsLayer from './VisualizationsLayer'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxSatellite from './MapboxSatellite'
import { IconButton, Paper, Typography } from '@mui/material'
import HighlightOff from '@mui/icons-material/HighlightOff'
import MapViewStyle from './MapView.module.css'
import { Session } from '@supabase/supabase-js'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.js'
import { useMapContext } from '../../context/map/mapContext.js'
import { Dataset } from '../../types/dataset.js'
import { useEffect, useMemo, useRef } from 'react'
import { viewport } from '@placemarkio/geo-viewport'
import { boundsStringToArray } from '../../utils'
import * as Sentry from '@sentry/react'
import { useCommentContext } from '../../context/comment/commentContext.js'
import { isFastTiler } from '../../utils'
type MapViewProps = {
    mapRef: React.RefObject<MapRef>
    initLocation: [number, number, number, number] | null
}

const customAttribution = `&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>
| &copy; <a href="https://www.maxar.com/">Maxar</a>
| <a href="http://www.openstreetmap.org/copyright">
    OpenStreetMap
</a>`

const initialMapState = {
    latitude: 30,
    longitude: 30,
    zoom: 1.5,
}

export type Tile = {
    x: number
    y: number
    z: number
    startTime: number
}

function isTileContained(inner: Tile, outer: Tile) {
    // Check for equality first
    if (inner.x === outer.x && inner.y === outer.y && inner.z === outer.z) {
        return true
    }
    return (
        inner.z > outer.z &&
        inner.x >= outer.x * 2 ** (inner.z - outer.z) &&
        inner.x < (outer.x + 1) * 2 ** (inner.z - outer.z) &&
        inner.y >= outer.y * 2 ** (inner.z - outer.z) &&
        inner.y < (outer.y + 1) * 2 ** (inner.z - outer.z)
    )
}

function MapView({ mapRef, initLocation }: MapViewProps) {
    const [flewToInitialLocation, setFlewToInitialLocation] =
        React.useState(false)

    if (initLocation && mapRef.current && !flewToInitialLocation) {
        const canvas = mapRef.current.getCanvas()
        const vp = viewport(initLocation, [
            parseInt(canvas.style['width']),
            parseInt(canvas.style['height']),
        ])
        mapRef.current.jumpTo({
            center: vp.center,
            zoom: vp.zoom,
        })
        setFlewToInitialLocation(true)
    }
    const [tilesBeingLoaded, setTilesBeingLoaded] = React.useState<Tile[]>([])
    const supabaseSessionRef = React.useRef<Session | null>(null)
    const [currentZoom, setCurrentZoom] = React.useState(initialMapState.zoom)
    const [viewportBounds, setViewportBounds] = React.useState<
        [number, number, number, number] | null
    >(null)
    const [mapError, setMapError] = React.useState<string | null>(null)
    const errorTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)

    const supabase = useSupabaseContext()
    const { state, dispatch } = useMapContext()
    const { state: commentState, openNewThread } = useCommentContext()

    // Without using a ref to the state here, the mapbox tile callback will use outdated
    // state when the callback is called, leading to JWT errors on the tiler
    supabaseSessionRef.current = supabase.session

    let _isDatasetVisible = (dataset: Dataset) => dataset.isVisible
    // filter then concat should avoid unnecessary copies
    const visibleDatasets = state.current.datasets
        .filter(_isDatasetVisible)
        .concat(state.catalog)
        .filter(_isDatasetVisible)

    function addTile(tile: Tile) {
        setTilesBeingLoaded((prevTiles) => {
            // Add timestamp to the tile
            const tileWithTime = { ...tile, startTime: Date.now() }

            // Remove any existing tiles that are contained within the new tile
            const newTiles = prevTiles.filter((t) => {
                return !isTileContained(t, tileWithTime)
            })

            // Check if the new tile is contained within any existing larger tiles
            const isContained = newTiles.some((t) =>
                isTileContained(tileWithTime, t)
            )

            // Only add the new tile if it's not contained within a larger existing tile
            return isContained ? newTiles : [...newTiles, tileWithTime]
        })
    }

    function removeTile(tile: Tile) {
        setTilesBeingLoaded((prevTiles) => {
            return prevTiles.filter(
                (t) => t.x !== tile.x || t.y !== tile.y || t.z !== tile.z
            )
        })
    }

    const handleZoom = (e) => {
        const newZoom = e.viewState.zoom
        if (newZoom != currentZoom) {
            setCurrentZoom(newZoom)
        }
    }

    async function handleMapClick(e: MapLayerMouseEvent) {
        if (commentState.isCommentMode) {
            openNewThread(e.lngLat)
            return
        }

        dispatch({
            type: 'SET_CLICKED_LAT_LNG',
            lngLat: [e.lngLat.lng, e.lngLat.lat] as [number, number],
        })
    }

    function handleMoveEnd() {
        if (mapRef.current) {
            const bounds = mapRef.current.getBounds()
            setViewportBounds([
                bounds.getWest(),
                bounds.getSouth(),
                bounds.getEast(),
                bounds.getNorth(),
            ])
        }
    }

    function handleMapError(
        // We need separate types for vercel vs local. Vercel includes
        // status in the error object, while local does not.
        e: ErrorEvent | { error: { message: string; status: number } }
    ) {
        // Normalize error structure
        const errorMessage = e.error.message
        const status = 'status' in e.error ? e.error.status : null

        // Early return for harmless errors
        if (errorMessage.includes('AbortError')) {
            return // Harmless: https://github.com/mapbox/mapbox-gl-js/issues/10498
        }

        // Map of error patterns to user messages
        const errorPatterns = {
            connection: {
                patterns: ['ERR_CONNECTION_REFUSED', 'Failed to fetch'],
                message: 'Failed to connect to tile server',
                status: null,
            },
            auth: {
                patterns: ['Unauthorized', '401'],
                status: 401,
                message: 'Authentication error: Please log in again',
            },
            forbidden: {
                patterns: ['403'],
                status: 403,
                message:
                    'Access denied: You do not have permission to view this data',
            },
            overloaded: {
                patterns: ['502'],
                status: 502,
                message:
                    'Server overloaded - toggle visibility and try again shortly',
            },
            server: {
                patterns: ['500'],
                status: 500,
                message: 'Server error - we are working on it!',
            },
        }

        // Find matching error pattern
        let userMessage = null
        for (const [, error] of Object.entries(errorPatterns)) {
            if (
                error.patterns.some((pattern) =>
                    errorMessage.includes(pattern)
                ) ||
                error.status === status
            ) {
                userMessage = error.message
                break
            }
        }

        // Default message if no pattern matched
        if (!userMessage) {
            userMessage = `Error: ${errorMessage}`
        }

        setMapError(userMessage)
        Sentry.captureException(e, { tags: { errorType: 'tiler_error' } })

        // Clear error after timeout
        if (errorTimeoutRef.current) {
            clearTimeout(errorTimeoutRef.current)
        }
        errorTimeoutRef.current = setTimeout(() => setMapError(null), 15000)
    }

    function boundsIntersect(
        bounds1: [number, number, number, number],
        bounds2: [number, number, number, number]
    ) {
        return (
            bounds1[0] <= bounds2[2] &&
            bounds1[2] >= bounds2[0] &&
            bounds1[1] <= bounds2[3] &&
            bounds1[3] >= bounds2[1]
        )
    }

    let datasetsInViewport: Dataset[] = []
    if (viewportBounds) {
        datasetsInViewport = visibleDatasets.filter((dataset: Dataset) => {
            const datasetBounds = boundsStringToArray(dataset.extent)
            return boundsIntersect(datasetBounds, viewportBounds)
        })
    }
    let viewportDatasetsMinZoomMaximum = 0
    if (datasetsInViewport.length > 0) {
        viewportDatasetsMinZoomMaximum = Math.max(
            ...datasetsInViewport.map((dataset: Dataset) => {
                const isFast = isFastTiler(dataset)
                return isFast ? 0 : dataset.minZoom || 0
            })
        )
    }

    return (
        <>
            {datasetsInViewport.length > 0 &&
                currentZoom < viewportDatasetsMinZoomMaximum && (
                    <Paper className={MapViewStyle.minZoomOverlay}>
                        <Typography variant="h6" padding={'0px 8px'}>
                            Please zoom in to render all datasets
                        </Typography>
                    </Paper>
                )}
            {mapError && (
                <Paper className={MapViewStyle.errorOverlay}>
                    <Typography
                        variant="h6"
                        padding={'0px 8px'}
                        sx={{ display: 'inline-block', marginRight: '8px' }}
                    >
                        {mapError}
                    </Typography>
                    <IconButton
                        onClick={() => setMapError(null)}
                        size="small"
                        sx={{ color: 'red' }}
                    >
                        <HighlightOff />
                    </IconButton>
                </Paper>
            )}
            <MapGLMap
                ref={mapRef}
                projection={state.globeView ? 'globe' : null}
                fog={{
                    color: '#cccccc',
                    'horizon-blend': 0.1,
                    'high-color': '#1d50c8',
                    'space-color': '#000000',
                    'star-intensity': 0.15,
                }}
                attributionControl={false}
                onZoomEnd={handleZoom}
                onMoveEnd={handleMoveEnd}
                onLoad={handleMoveEnd}
                onClick={handleMapClick}
                onRender={(e) => {
                    if (e.target.areTilesLoaded()) {
                        setTilesBeingLoaded([])
                    }
                }}
                onError={handleMapError}
                onData={(e: MapSourceDataEvent) => {
                    // As soon as tile info is available, we are done rendering.
                    // e.isSourceLoaded is a bit laggy.
                    const isSource =
                        e?.sourceId?.includes('raster-source') ||
                        e?.sourceId?.includes('vector-source')
                    const hasTile = e.tile !== undefined
                    if (!isSource || !hasTile) {
                        return
                    }
                    const canonicalTile = e.tile.tileID.canonical
                    const tile = {
                        x: canonicalTile.x,
                        y: canonicalTile.y,
                        z: canonicalTile.z,
                    }
                    removeTile(tile)
                }}
                cursor="pointer"
                onIdle={() => {
                    // This clears any requests that were made but not rendered due
                    // to zooming in/out.
                    if (tilesBeingLoaded.length > 0) {
                        setTilesBeingLoaded([])
                    }
                }}
                transformRequest={(url, resourceType) => {
                    if (resourceType === 'Tile' && url.includes('dataset')) {
                        const tileMatch = url.match(
                            /\/tiles\/(\d+)\/(\d+)\/(\d+)\.*/
                        )

                        if (tileMatch) {
                            const [, z, x, y] = tileMatch.map(Number)
                            const tile: Tile = { x, y, z }

                            addTile(tile)
                        }

                        return {
                            url: url,
                            headers: {
                                Authorization:
                                    supabaseSessionRef.current?.access_token,
                            },
                        }
                    }
                }}
                initialViewState={initialMapState}
                mapboxAccessToken={import.meta.env.EARTHSCALE_MAPBOX_TOKEN}
                logoPosition={'bottom-right'}
            >
                <MapboxSatellite />
                <VisualizationsLayer
                    currentZoom={currentZoom}
                    mapRef={mapRef}
                    tilesBeingLoaded={tilesBeingLoaded}
                />
                {/* <DatasetBoundsLayer /> */}
                <AttributionControl
                    compact={true}
                    customAttribution={customAttribution}
                />
            </MapGLMap>
        </>
    )
}

export default React.memo(MapView)
