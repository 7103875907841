import React from 'react'
import { FormGroup, InputAdornment, TextField, useTheme } from '@mui/material'
import { Search, Send } from '@mui/icons-material'

type SearchBarProps = {
    placeholder: string
    searchTerm: string | null
    onSearchChange: (string) => void
    onSubmit: () => void
    showSearchIcon: boolean
    disabled: boolean
}

export default function SearchBar({
    placeholder,
    searchTerm,
    onSearchChange,
    onSubmit,
    onClick,
    showSearchIcon = true,
    disabled = false,
}: SearchBarProps) {
    const theme = useTheme()

    const startAdornment = showSearchIcon ? (
        <InputAdornment position="end">
            <Search sx={{ color: theme.palette.primary.light }} />
        </InputAdornment>
    ) : null
    const endAdornment = !showSearchIcon ? (
        <InputAdornment position="start">
            <Send sx={{ color: theme.palette.primary.light }} />
        </InputAdornment>
    ) : null

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            value={searchTerm}
            onChange={(e) => {
                onSearchChange(e.target.value)
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && onSubmit) {
                    onSubmit()
                }
            }}
            disabled={disabled}
            onClick={onClick}
            InputProps={{
                style: {
                    ...theme.typography.body2,
                    padding: '0px',
                    borderRadius: '8px',
                },
                startAdornment: startAdornment,
                endAdornment: endAdornment,
            }}
            sx={{
                width: '100%',
                marginBottom: '8px',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'primary.light',
                    },
                    '& input': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        paddingLeft: '8px',
                        paddingRight: '16px',
                    },
                },
            }}
        />
    )
}
