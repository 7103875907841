/* The Workspace contains (1) the map of datasets (2) a sidebar controlling which datasets to view and how to visualize them. */
import * as React from 'react'
import Box from '@mui/material/Box'
import { Button, Stack } from '@mui/material'
import Sidebar from '../../components/Sidebar/Sidebar'
import MapView from '../../components/MapView/MapView'
import WorkspaceStyle from './Workspace.module.css'
import { MapRef } from 'react-map-gl'
import { viewport } from '@placemarkio/geo-viewport'
import { boundsStringToArray } from '../../utils'
import { Dataset } from '../../types/dataset'
import PixelInfoView from '../../components/PixelInfoView/PixelInfoView'
import CommentModeBox from '../../components/CommentModeBox/CommentModeBox'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import DatasetCatalog from '../../components/DatasetCatalog/DatasetCatalog'
import DatasetDetails from '../../components/DatasetDetails/DatasetDetails'
import { useParams } from 'react-router-dom'
import { useMapContext } from '../../context/map/mapContext'
import ShareBox from '../../components/ShareBox/ShareBox'
import CacheManager from '../../context/cache'
import CommentThreadBox from '../../components/CommentThreadBox/CommentThreadBox'
import NewThreadBox from '../../components/NewThreadBox/NewThreadBox'
import { useCommentContext } from '../../context/comment/commentContext'
import { feedbackIntegration } from '@sentry/react'
import FeedbackButton from '../../components/FeedbackButton/FeedbackButton'
import GlobeViewButton from '../../components/GlobeViewButton/GlobeViewButton'
import { fetchDatasets, subscribeToDatasetChanges } from '../../api/dataset'
import OnboardingTour from '../../components/OnboardingTour/OnboardingTour'

type ActiveDetailPanel = 'dataset' | 'pixel' | 'comment' | 'newThread' | null

const flyPadding = 300 // Some padding so you can see the bounds when flying/jumping

function Workspace() {
    const supabaseContext = useSupabaseContext()

    const { id } = useParams() // Map ID for /map/:id routing
    const { changeMap, state: mapState, dispatch } = useMapContext()
    const { state: commentState, setCommentMode } = useCommentContext()
    const { user } = useSupabaseContext()
    const mapRef = React.useRef<MapRef>(null)
    const [datasetCatalogIsOpen, setDatasetCatalogIsOpen] =
        React.useState(false)

    const [activeDetailPanel, setActiveDetailPanel] =
        React.useState<ActiveDetailPanel>(null)
    const initLocation = mapState.initialViewState
        ? mapState.initialViewState.bbox
        : JSON.parse(CacheManager.getItem('lastLocation'))

    React.useEffect(() => {
        if (commentState.draftThread) {
            setActiveDetailPanel('newThread')
        } else if (commentState.selectedThreadId) {
            setActiveDetailPanel('comment')
        } else if (mapState.clickedLngLat) {
            setActiveDetailPanel('pixel')
        } else if (mapState.selectedDatasetVersionId) {
            setActiveDetailPanel('dataset')
        } else {
            setActiveDetailPanel(null)
        }
    }, [
        mapState.selectedDatasetVersionId,
        commentState.selectedThreadId,
        commentState.draftThread,
        mapState.clickedLngLat,
    ])

    React.useEffect(() => {
        if (id) {
            changeMap(id)
        } else if (user.default_map_id) {
            changeMap(user.default_map_id)
        }
    }, [id, user.default_map_id])

    const fetchCatalog = async (client) => {
        fetchDatasets(supabaseContext.client).then((datasets) => {
            dispatch({
                type: 'SET_CATALOG',
                datasets: datasets,
            })
        })
    }

    // Fetch once at the start and subscribe to changes
    React.useEffect(() => {
        fetchCatalog(supabaseContext.client)
        subscribeToDatasetChanges(supabaseContext.client, (payload) => {
            fetchCatalog(supabaseContext.client)
        })
    }, [])

    const flyToBounds = (bounds: [number, number, number, number]) => {
        if (mapRef.current) {
            const map = mapRef.current
            const canvas = map.getCanvas()

            // Some padding so you can see the bounds
            const vp = viewport(bounds, [
                parseInt(canvas.style['width']) - flyPadding,
                parseInt(canvas.style['height']) - flyPadding,
            ])
            mapRef.current.flyTo({
                center: vp.center,
                zoom: vp.zoom,
                duration: 1000,
                essential: true,
            })
        }
    }

    const flyToDatasetBounds = (dataset: Dataset) => {
        // Bounds are only available for ready datasets
        if (dataset.status === 'ready') {
            const bounds = boundsStringToArray(dataset.extent)
            flyToBounds(bounds)
        }
    }

    React.useEffect(() => {
        const handleBeforeUnload = () => {
            if (mapRef.current) {
                const bounds = mapRef.current.getBounds()
                const boundsArray = [
                    bounds.getWest(),
                    bounds.getSouth(),
                    bounds.getEast(),
                    bounds.getNorth(),
                ]
                CacheManager.setItem(
                    'lastLocation',
                    JSON.stringify(boundsArray)
                )
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [mapRef])

    let maxHeight = null
    if (datasetCatalogIsOpen) {
        // If the dataset catalog is open, both the sidebar as well as the catalog
        // share the left side of the screen equally. We'll need to reduce the full
        // height of the screen by 8px on the top and bottom as well as 8px in
        // between them.
        // Not quite sure why we have to reduce more than 24 pixels, probably padding?
        maxHeight = 'calc((100vh - 60px) / 2)'
    }

    const handleKeyPress = React.useCallback((event) => {
        if (commentState.isCommentMode && event.key === 'Escape') {
            setCommentMode(false)
        }
    })

    React.useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress)

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    }, [handleKeyPress])

    const disableOnboarding =
        import.meta.env.EARTHSCALE_DISABLE_ONBOARDING === 'true' ||
        user.onboarding_completed

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    position: 'fixed',
                    top: '16px',
                    zIndex: 1000,
                    right: '16px',
                }}
            >
                <GlobeViewButton />
                <FeedbackButton key={'feedback-button'} />
                <ShareBox mapRef={mapRef} />
                <CommentModeBox />
            </Stack>
            <Box className={WorkspaceStyle.sidebarBox}>
                <Sidebar
                    flyToDatasetBounds={flyToDatasetBounds}
                    maxHeight={maxHeight}
                    setCatalogOpen={setDatasetCatalogIsOpen}
                    mapRef={mapRef}
                />
            </Box>

            <Box className={WorkspaceStyle.datasetCatalog} id="datasetCatalog">
                <DatasetCatalog
                    isOpen={datasetCatalogIsOpen}
                    setIsOpen={setDatasetCatalogIsOpen}
                    maxHeight={maxHeight}
                    flyToDatasetBounds={flyToDatasetBounds}
                    mapRef={mapRef}
                />
            </Box>

            <Box className={WorkspaceStyle.map}>
                <MapView mapRef={mapRef} initLocation={initLocation} />
            </Box>

            <Box className={WorkspaceStyle.details}>
                <Stack spacing={1}>
                    {activeDetailPanel === 'dataset' && (
                        <DatasetDetails
                            setActiveDetailPanel={setActiveDetailPanel}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                    )}
                    {activeDetailPanel === 'pixel' && (
                        <PixelInfoView
                            setActiveDetailPanel={setActiveDetailPanel}
                        />
                    )}
                    {activeDetailPanel === 'comment' &&
                        commentState.selectedThreadId && <CommentThreadBox />}
                    {activeDetailPanel === 'newThread' &&
                        commentState.draftThread && <NewThreadBox />}
                </Stack>
            </Box>
            {!disableOnboarding && <OnboardingTour />}
        </>
    )
}

export default Workspace
