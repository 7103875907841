/* Map layer displaying all visible dataset. */
import React from 'react'
import { Layer, Source } from 'react-map-gl'
import { MapRef } from 'react-map-gl'
import { Dataset } from '../../types/dataset'
import { boundsStringToArray } from '../../utils'
import { VectorVizParams } from '../../types/viz'
import { createTileServerURL } from '../../api/tiler'
import { Tile } from './MapView'
import { useMapContext } from '../../context/map/mapContext'
import { useTheme } from '@mui/material'
import { loadLocalDatasets } from '../../api/localDataset'
import CommentThreadLayer from '../../components/CommentThreadLayer/CommentThreadLayer'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import { isFastTiler } from '../../utils'

type DatasetLayerProps = {
    dataset: Dataset
}

type VisualizationsLayerProps = {
    currentZoom: number
    mapRef: React.RefObject<MapRef>
    tilesBeingLoaded: Tile[]
}

function boundsToPolygon(
    bounds: [number, number, number, number]
): number[][][] {
    return [
        [
            [bounds[0], bounds[1]],
            [bounds[0], bounds[3]],
            [bounds[2], bounds[3]],
            [bounds[2], bounds[1]],
            [bounds[0], bounds[1]],
        ],
    ]
}

function layerID(dataset: Dataset, suffix?: string) {
    const baseId =
        dataset.type === 'vector'
            ? `dataset-${dataset.id}-vector-layer-${(dataset.vizParams as VectorVizParams).mode}`
            : `dataset-${dataset.id}-raster-layer`
    return suffix ? `${baseId}-${suffix}` : baseId
}

const TILES_BEING_LOADED_LAYER_ID = 'tiles-being-loaded-layer'
const TILE_LOADING_VISUALIZATION_DELAY_MS = 250

function VectorDatasetLayer({ dataset }: DatasetLayerProps) {
    if (!dataset.vizParams) {
        return null
    }

    const vizParams: VectorVizParams = dataset.vizParams as VectorVizParams
    const mode = vizParams.mode

    type GeometryConfig = {
        [key in 'point' | 'line' | 'polygon']: {
            type: 'circle' | 'line' | 'fill'
            filter: [
                '==',
                ['geometry-type'],
                'Point' | 'LineString' | 'Polygon',
            ]
            paint: {
                'circle-color'?: string
                'circle-opacity'?: number
                'circle-radius'?: number
                'line-color'?: string
                'line-width'?: number
                'line-opacity'?: number
                'fill-color'?: string
                'fill-opacity'?: number
            }
        }
    }

    const geometryConfig: GeometryConfig = {
        point: {
            type: 'circle',
            filter: ['==', ['geometry-type'], 'Point'],
            paint: {
                'circle-color': vizParams.color,
                'circle-opacity': dataset.opacity / 100,
                'circle-radius': vizParams.width || 5,
            },
        },
        line: {
            type: 'line',
            filter: ['==', ['geometry-type'], 'LineString'],
            paint: {
                'line-color': vizParams.color,
                'line-width': vizParams.width,
                'line-opacity': dataset.opacity / 100,
            },
        },
        polygon: {
            type: mode === 'fill' ? 'fill' : 'line',
            filter: ['==', ['geometry-type'], 'Polygon'],
            paint:
                mode === 'fill'
                    ? {
                          'fill-color': vizParams.color,
                          'fill-opacity': dataset.opacity / 100,
                      }
                    : {
                          'line-color': vizParams.color,
                          'line-width': vizParams.width,
                          'line-opacity': dataset.opacity / 100,
                      },
        },
    }

    const createLayer = (geometryType: keyof typeof geometryConfig) => {
        const config = geometryConfig[geometryType]
        return (
            <Layer
                id={`${layerID(dataset)}-${geometryType}`}
                type={config.type}
                {...(dataset.source !== 'local'
                    ? { 'source-layer': 'default' }
                    : {})}
                filter={config.filter}
                paint={config.paint}
            />
        )
    }

    if (dataset.source === 'local' && dataset.localDataset) {
        return (
            <Source
                id={dataset.id + '-vector-source' + '-' + mode}
                type="geojson"
                data={dataset.localDataset.geometry}
                key={dataset.id + '-vector-source' + '-' + mode}
            >
                {createLayer('point')}
                {createLayer('line')}
                {createLayer('polygon')}
            </Source>
        )
    }

    return (
        <Source
            id={dataset.id + '-vector-source' + '-' + mode}
            type="vector"
            tiles={[createTileServerURL(dataset)]}
            bounds={boundsStringToArray(dataset.extent)}
            key={dataset.id + '-vector-source' + '-' + mode}
            minzoom={dataset.minZoom || 0}
            {...(dataset.maxZoom ? { maxzoom: dataset.maxZoom } : {})}
        >
            {createLayer('point')}
            {createLayer('polygon')}
        </Source>
    )
}

function RasterDatasetLayer({ dataset }: DatasetLayerProps) {
    const { user } = useSupabaseContext()
    const tileServerURL = createTileServerURL(dataset, user)

    const isFast = isFastTiler(dataset)
    const minZoom = isFast ? 0 : dataset.minZoom || 0
    const maxZoom = isFast
        ? dataset.rasterOverviewsStartZoom
        : dataset.maxZoom || 22

    return (
        <Source
            id={dataset.id + '-raster-source'}
            type="raster"
            tiles={[tileServerURL]}
            bounds={boundsStringToArray(dataset.extent)}
            key={dataset.id + '-raster-source'}
            minzoom={minZoom}
            {...(maxZoom ? { maxzoom: maxZoom } : {})}
        >
            <Layer
                id={layerID(dataset)}
                type="raster"
                paint={{
                    'raster-resampling': 'nearest',
                    'raster-opacity': dataset.opacity / 100,
                }}
            />
        </Source>
    )
}

function BoundsLayer({
    dataset,
    hovered,
}: {
    dataset: Dataset
    hovered: boolean
}) {
    const theme = useTheme()

    let lineColor = '#888'
    let lineWidth = 1
    if (hovered) {
        lineColor = theme.palette.primary.main
        lineWidth = 3
    }

    if (!dataset.extent) {
        return null
    }

    return (
        <React.Fragment key={dataset.id + '-bounds-fragment'}>
            <Source
                id={dataset.id + '-bounds-source'}
                type="geojson"
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: boundsToPolygon(
                            boundsStringToArray(dataset.extent)
                        ),
                    },
                }}
            >
                <Layer
                    id={dataset.id + '-bounds-layer'}
                    type="line"
                    paint={{
                        'line-color': lineColor,
                        'line-opacity': 1.0,
                        'line-width': lineWidth,
                    }}
                />
            </Source>
        </React.Fragment>
    )
}

/* From: https://gis.stackexchange.com/questions/390199/getting-latlngbounds-for-tile-based-on-its-tile-coordinate-using-leaflet */
function getLatLngForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number] {
    let n = Math.pow(2.0, zoom)
    let lng = (xtile / n) * 360.0 - 180.0
    let lat_rad = Math.atan(Math.sinh(Math.PI * (1 - (2.0 * ytile) / n)))
    let lat = (180.0 * lat_rad) / Math.PI
    return [lat, lng]
}

function getBoundsForTile(
    xtile: number,
    ytile: number,
    zoom: number
): [number, number, number, number] {
    const northWest = getLatLngForTile(xtile, ytile, zoom)
    const southEast = getLatLngForTile(xtile + 1, ytile + 1, zoom)
    return [northWest[1], northWest[0], southEast[1], southEast[0]]
}

function TileLoadingLayer({ tilesBeingLoaded }: { tilesBeingLoaded: Tile[] }) {
    // Filter tiles that have been loading for more than N milliseconds
    const longLoadingTiles = tilesBeingLoaded.filter(
        (tile) =>
            Date.now() - tile.startTime > TILE_LOADING_VISUALIZATION_DELAY_MS
    )

    // Don't render anything if no tiles have been loading long enough
    if (longLoadingTiles.length === 0) {
        return null
    }

    return (
        <Source
            id="tiles-being-loaded-source"
            type="geojson"
            data={{
                type: 'FeatureCollection',
                features: longLoadingTiles.map((tile) => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: boundsToPolygon(
                            getBoundsForTile(tile.x, tile.y, tile.z)
                        ),
                    },
                })),
            }}
        >
            <Layer
                id={`${TILES_BEING_LOADED_LAYER_ID}-fill`}
                type="fill"
                paint={{
                    'fill-color': '#B0C4DE', // Light Steel Blue (pale blue gray)
                    'fill-opacity': 0.35,
                }}
            />
            <Layer
                id={`${TILES_BEING_LOADED_LAYER_ID}-outline`}
                type="line"
                paint={{
                    'line-color': '#000000', // Black for the outline
                    'line-width': 2,
                    'line-opacity': 0.2,
                }}
            />
        </Source>
    )
}

function VisualizationsLayer({
    currentZoom,
    mapRef,
    tilesBeingLoaded,
}: VisualizationsLayerProps) {
    const { state } = useMapContext()
    const datasets = [...state.current.datasets, ...state.catalog]

    const readyDatasets = datasets.filter((dataset) => {
        return dataset.status === 'ready'
    })

    const hasVizParams = (dataset: Dataset) =>
        dataset.vizParams !== undefined ||
        (dataset.className === 'EarthEngineDataset' &&
            dataset?.earthEngineVisualizations.length > 0) ||
        dataset.className === 'TileServerDataset'
    const visibleDatasets = readyDatasets.filter(
        (dataset) => dataset.isVisible && hasVizParams(dataset)
    )

    const hoveredDatasetId = state.hoveredCardDatasetVersionId
    const readyMapDatasets = state.current.datasets
        .filter((dataset) => {
            return dataset.status === 'ready'
        })
        .filter((dataset) => dataset.id != hoveredDatasetId)
    const hoveredDataset = datasets.find(
        (dataset) => dataset.id == hoveredDatasetId
    )

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current) {
                const reversedVisibleDatasets = [...visibleDatasets].reverse()

                // reorder the datasets based on the layer order
                reversedVisibleDatasets.forEach((dataset) => {
                    if (dataset.type === 'vector') {
                        // Move all geometry type layers
                        ;['point', 'polygon'].forEach((type) => {
                            const id = `${layerID(dataset)}-${type}`
                            if (mapRef.current?.getLayer(id)) {
                                mapRef.current.moveLayer(id)
                            }
                        })
                    } else {
                        mapRef.current.moveLayer(layerID(dataset))
                    }
                })

                // move loading layer to the front if it exists
                if (
                    mapRef.current.getLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-fill'
                    )
                ) {
                    mapRef.current.moveLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-fill'
                    )
                    mapRef.current.moveLayer(
                        TILES_BEING_LOADED_LAYER_ID + '-outline'
                    )
                }
            }
        }, 100)

        return () => clearInterval(interval)
    }, [visibleDatasets, mapRef])

    return (
        <>
            <CommentThreadLayer />
            {visibleDatasets.map((dataset: Dataset) => (
                <React.Fragment key={dataset.id + '-fragment'}>
                    {dataset.type === 'vector' ? (
                        <VectorDatasetLayer
                            key={dataset.id + '-vector'}
                            dataset={dataset}
                        />
                    ) : (
                        <RasterDatasetLayer
                            key={dataset.id + '-raster'}
                            dataset={dataset}
                        />
                    )}
                </React.Fragment>
            ))}
            {readyMapDatasets.map((dataset: Dataset) => (
                <BoundsLayer
                    key={dataset.id + '-bounds'}
                    dataset={dataset}
                    hovered={false}
                />
            ))}
            {hoveredDataset && (
                <BoundsLayer
                    key={hoveredDataset.id + '-bounds'}
                    dataset={hoveredDataset}
                    hovered={true}
                />
            )}
            <TileLoadingLayer tilesBeingLoaded={tilesBeingLoaded} />
        </>
    )
}

export default VisualizationsLayer
