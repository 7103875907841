import { Map } from '../../types/map.js'
import { Dataset, VizType } from '../../types/dataset.js'
import { produce } from 'immer'
import { VizParams } from '../../types/viz.js'
import { datasetCopyHydratedProperties } from '../../api/dataset'
import { deepClone } from '@mui/x-data-grid/internals'

// Schema version of the map state, we can use this to migrate state saved to local
// storage. Or in case we forget to migrate cleanly, we can at least invalidate
// state on the user side
export const MAP_SCHEMA_VERSION = '0.2'

export type ViewState = {
    bbox: [number, number, number, number]
    visible: string[] // shortened dataset IDs
}

export type MapState = {
    current: Map | null
    clickedLngLat: [number, number] | null
    selectedDatasetVersionId: string | null
    hoveredCardDatasetVersionId: string | null
    // This catalog objects holds all datasets (revisions), which are not on the
    // current map
    catalog: Dataset[]
    initialViewState: ViewState | null
    globeView: boolean
}

export const initialMapState: MapState = {
    current: null,
    clickedLngLat: null,
    selectedDatasetVersionId: null,
    hoveredCardDatasetVersionId: null,
    catalog: [],
    initialViewState: null,
    globeView: false,
}

export type MapAction =
    | { type: 'SET_CURRENT'; map: Map }
    | { type: 'DELETE_MAP'; mapId: string }
    | { type: 'ADD_DATASET_TO_MAP'; dataset: Dataset }
    | { type: 'REMOVE_DATASET_FROM_MAP'; datasetVersionId: string }
    | { type: 'UPDATE_MAP_METADATA'; name: string; description: string }
    | { type: 'TOGGLE_DATASET_VISIBILITY'; datasetVersionId: string }
    | { type: 'TOGGLE_DATASET_EDITING'; datasetVersionId: string }
    | {
          type: 'SET_SELECTED_DATE_INDEX'
          datasetVersionId: string
          dateIndex: number
      }
    | { type: 'SET_CLICKED_LAT_LNG'; lngLat: [number, number] }
    | {
          type: 'UPDATE_VIZ_PARAMS'
          datasetVersionId: string
          vizId: string
          vizType: VizType
          vizParams: VizParams
      }
    | { type: 'SELECT_DATASET'; datasetVersionId: string }
    | {
          type: 'UPDATE_DATASET_OPACITY'
          datasetVersionId: string
          opacity: number
      }
    | { type: 'HOVER_CARD'; datasetVersionId: string }
    | { type: 'SET_CATALOG'; datasets: Dataset[] }
    | { type: 'HIDE_ALL_CATALOG_DATASETS' }
    | { type: 'UPDATE_LAYER_ORDER'; datasets: Dataset[] }
    | { type: 'DELETE_DATASET_FROM_CATALOG'; datasetVersionId: string }
    | {
          type: 'SELECT_EARTH_ENGINE_VISUALIZATION'
          datasetVersionId: string
          idx: number
      }
    | {
          type: 'SET_SELECTED_DIMENSIONS'
          datasetVersionId: string
          dimensions: { [key: string]: number }
      }
    | { type: 'TOGGLE_GLOBE_VIEW' }

export const mapReducer = (state: MapState, action: MapAction): MapState => {
    switch (action.type) {
        case 'SET_CURRENT': {
            return produce(state, (draftState) => {
                if (
                    !draftState.current ||
                    draftState.current.metadata.id !== action.map.metadata.id
                ) {
                    draftState.current = action.map
                    return
                }
                const mapDatasetsById = draftState.current.datasets.reduce(
                    (acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    },
                    {}
                )
                // map is the same, but maybe the datasets have changed
                for (const newDataset of action.map.datasets) {
                    const oldDataset = mapDatasetsById[newDataset.datasetId]
                    if (oldDataset) {
                        if (
                            oldDataset.id !== newDataset.id &&
                            oldDataset.updatedAt < newDataset.updatedAt
                        ) {
                            oldDataset.isNewVersionAvailable = true
                        }
                        continue
                    }
                    draftState.current.datasets.push(newDataset)
                }
            })
        }

        case 'DELETE_MAP': {
            return produce(state, (draftState) => {
                if (
                    draftState.current &&
                    draftState.current.metadata.id === action.mapId
                ) {
                    draftState.current = null
                }
            })
        }

        case 'ADD_DATASET_TO_MAP': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }

                // already on map
                if (
                    draftState.current.datasets.some(
                        (dataset) =>
                            dataset.datasetId === action.dataset.datasetId
                    )
                ) {
                    return
                }
                const datasetCopy = deepClone(action.dataset)
                datasetCopy.isVisible = true
                draftState.current.datasets.push(datasetCopy)
            })
        }

        case 'UPDATE_LAYER_ORDER': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                draftState.current.datasets = action.datasets
            })
        }

        case 'REMOVE_DATASET_FROM_MAP': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                const datasetToRemove = draftState.current.datasets.find(
                    (dataset) => dataset.id === action.datasetVersionId
                )
                if (!datasetToRemove) {
                    console.error(
                        `Could not find dataset with version id ${action.datasetVersionId} to remove`
                    )
                    return
                }
                datasetToRemove.isVisible = false
                draftState.current.datasets =
                    draftState.current.datasets.filter(
                        (dataset) => dataset.id !== action.datasetVersionId
                    )
            })
        }

        case 'UPDATE_MAP_METADATA': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                draftState.current.metadata.name = action.name
                draftState.current.metadata.description = action.description
            })
        }

        case 'TOGGLE_DATASET_VISIBILITY': {
            return produce(state, (draftState) => {
                if (draftState.current) {
                    for (const dataset of draftState.current.datasets) {
                        if (dataset.id === action.datasetVersionId) {
                            dataset.isVisible = !dataset.isVisible
                            return
                        }
                    }
                }
                for (const dataset of draftState.catalog) {
                    if (dataset.id === action.datasetVersionId) {
                        dataset.isVisible = !dataset.isVisible
                        return
                    }
                }
                console.error(
                    `Could not find dataset with version id ${action.datasetVersionId} to toggle visibility`
                )
            })
        }

        case 'TOGGLE_DATASET_EDITING': {
            return produce(state, (draftState) => {
                if (draftState.current) {
                    for (const dataset of draftState.current.datasets) {
                        if (dataset.id === action.datasetVersionId) {
                            dataset.isBeingEdited = !dataset.isBeingEdited
                            return
                        }
                    }
                }
                for (const dataset of draftState.catalog) {
                    if (dataset.id === action.datasetVersionId) {
                        dataset.isBeingEdited = !dataset.isBeingEdited
                        return
                    }
                }
                console.error(
                    `Could not find dataset with version id ${action.datasetVersionId} to toggle editing`
                )
            })
        }

        case 'SET_SELECTED_DIMENSIONS': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                for (const dataset of draftState.current.datasets) {
                    if (dataset.id === action.datasetVersionId) {
                        dataset.selectedDimensions = action.dimensions
                        return
                    }
                }
                for (const dataset of draftState.catalog) {
                    if (dataset.id === action.datasetVersionId) {
                        dataset.selectedDimensions = action.dimensions
                        return
                    }
                }
                console.error(
                    `Could not find dataset with version id ${action.datasetVersionId} to set selected dimensions`
                )
            })
        }

        case 'SET_CLICKED_LAT_LNG': {
            return produce(state, (draftState) => {
                draftState.clickedLngLat = action.lngLat
                draftState.selectedDatasetVersionId = null
            })
        }

        case 'UPDATE_VIZ_PARAMS': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                const dataset = [
                    ...draftState.current.datasets,
                    ...draftState.catalog,
                ].find((dataset) => dataset.id === action.datasetVersionId)
                if (!dataset) {
                    console.error(
                        `Could not find dataset with version id ${action.datasetVersionId} to update viz params`
                    )
                    return
                }
                dataset.vizParams = action.vizParams
                dataset.vizId = action.vizId
                dataset.vizType = action.vizType
            })
        }

        case 'SELECT_DATASET': {
            return produce(state, (draftState) => {
                draftState.selectedDatasetVersionId = action.datasetVersionId
                draftState.clickedLngLat = null
            })
        }

        case 'UPDATE_DATASET_OPACITY': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                const dataset = [
                    ...draftState.current.datasets,
                    ...draftState.catalog,
                ].find((dataset) => dataset.id === action.datasetVersionId)
                if (!dataset) {
                    console.error(
                        `Could not find dataset with version id ${action.datasetVersionId} to set opacity`
                    )
                    return
                }
                dataset.opacity = action.opacity
            })
        }

        case 'HOVER_CARD': {
            return produce(state, (draftState) => {
                draftState.hoveredCardDatasetVersionId = action.datasetVersionId
            })
        }

        case 'SET_CATALOG': {
            return produce(state, (draftState) => {
                const catalogDatasetsById = draftState.catalog.reduce(
                    (acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    },
                    {}
                )
                const mapDatasetsById =
                    draftState.current?.datasets.reduce((acc, dataset) => {
                        acc[dataset.datasetId] = dataset
                        return acc
                    }, {}) || {}

                const updatedCatalog = []
                for (const newDataset of action.datasets) {
                    const mapDataset = mapDatasetsById[newDataset.datasetId]
                    const catalogDataset =
                        catalogDatasetsById[newDataset.datasetId]

                    if (mapDataset) {
                        if (mapDataset.id !== newDataset.id) {
                            mapDataset.isNewVersionAvailable =
                                newDataset.updatedAt > mapDataset.updatedAt
                        }
                    }

                    // same dataset version
                    if (catalogDataset) {
                        if (catalogDataset.id !== newDataset.id) {
                            catalogDataset.isNewVersionAvailable =
                                newDataset.updatedAt > catalogDataset.updatedAt
                        }
                        // update the new version with the hydrated properties
                        datasetCopyHydratedProperties(
                            catalogDataset,
                            deepClone(newDataset)
                        )
                    }

                    updatedCatalog.push(newDataset)
                }
                draftState.catalog = updatedCatalog
            })
        }

        case 'HIDE_ALL_CATALOG_DATASETS': {
            return produce(state, (draftState) => {
                for (const dataset of draftState.catalog) {
                    dataset.isVisible = false
                }
            })
        }

        case 'DELETE_DATASET_FROM_CATALOG': {
            return produce(state, (draftState) => {
                draftState.catalog = draftState.catalog.filter(
                    (dataset) => dataset.id !== action.datasetVersionId
                )
            })
        }

        case 'SELECT_EARTH_ENGINE_VISUALIZATION': {
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    return
                }
                const dataset = [
                    ...draftState.current.datasets,
                    ...draftState.catalog,
                ].find((dataset) => dataset.id === action.datasetVersionId)
                if (!dataset) {
                    console.error(
                        `Could not find dataset with version id ${action.datasetVersionId} to select Earth Engine visualization`
                    )
                    return
                }
                if (
                    !dataset.earthEngineVisualizations ||
                    dataset.earthEngineVisualizations.length <= action.idx ||
                    action.idx < 0
                ) {
                    console.error(
                        `Could not find Earth Engine visualization with index ${action.idx} for dataset with version id ${action.datasetVersionId}`
                    )
                    return
                }
                dataset.selectedEarthEngineVisualizationIndex = action.idx
            })
        }

        case 'TOGGLE_GLOBE_VIEW': {
            return produce(state, (draftState) => {
                draftState.globeView = !draftState.globeView
            })
        }

        default:
            return state
    }
}
