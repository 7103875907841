import { Layer, Source } from 'react-map-gl'
import React from 'react'

function MapboxSatellite() {
    return (
        <>
            <Source
                type="raster"
                tiles={[
                    'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg?access_token=' +
                        import.meta.env.EARTHSCALE_MAPBOX_TOKEN,
                ]}
            >
                <Layer id={'mapbox-satellite'} type="raster" />
            </Source>
        </>
    )
}

export default MapboxSatellite
