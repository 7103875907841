import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material'

interface ErrorDialogProps {
    open: boolean
    message: string
    onClose: () => void
    onLogout: () => void
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
    open,
    message,
    onClose,
    onLogout,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">Error</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onLogout} color="primary" autoFocus>
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorDialog
