import React, { useContext, useEffect, useReducer, useState } from 'react'
import {
    initialMapState,
    MAP_SCHEMA_VERSION,
    mapReducer,
    MapState,
    ViewState,
} from './mapReducer.ts'
import { MapContext } from './mapContext.ts'
import {
    addDatasetToMap,
    createMap,
    deleteMapFromDatabase,
    fetchMapById,
    removeDatasetFromMap,
    updateLayerOrderForMap,
    updateVizParamsForMap,
} from '../../api/map.ts'
import { useSupabaseContext } from '../supabase/supabaseContext.ts'
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen.tsx'
import { Dataset, VizType } from '../../types/dataset.ts'
import { deleteDataset, insertVizParams } from '../../api/dataset.ts'
import { VizParams } from '../../types/viz.ts'
import { v4 as uuid } from 'uuid'
import { getOrgId } from '../../api/user.ts'
import * as Sentry from '@sentry/react'
import CacheManager from '../cache.ts'
import {
    convertLocalDatasetToDataset,
    loadLocalDatasets,
    loadLocalDatasetsForMap,
    removeLocalDataset,
} from '../../api/localDataset.ts'
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog.tsx'

const MAP_STATE_KEY = 'map_state'

type LocalMapState = {
    schemaVersion: string
    state: MapState
}

type mapProviderProps = {
    fallbackMapId: string
    children: React.ReactNode
    initialViewState?: ViewState
}

const MapProvider = ({
    fallbackMapId,
    children,
    initialViewState,
}: mapProviderProps) => {
    // Try to load initial state from local storage
    const localJSONState = CacheManager.getItem(MAP_STATE_KEY)
    const localState: LocalMapState = localJSONState
        ? JSON.parse(localJSONState)
        : null

    let initState = initialMapState

    if (initialViewState) {
        initState.initialViewState = initialViewState
    }

    if (
        localState?.state?.current &&
        localState.schemaVersion == MAP_SCHEMA_VERSION
    ) {
        // Make sure the catalog exists
        if (!localState.state.catalog) {
            localState.state.catalog = []
        }

        initState = localState.state
        // Convert all dates to date objects
        initState.current.datasets.forEach((dataset) => {
            if (dataset.dates) {
                dataset.dates = dataset.dates.map((date) => new Date(date))
            }
        })

        // Convert all datacatalog dates to date objects
        initState.catalog.forEach((dataset) => {
            if (dataset.dates) {
                dataset.dates = dataset.dates.map((date) => new Date(date))
            }
        })
    }

    const [mapState, dispatch] = useReducer(mapReducer, initState, undefined)
    const supabase = useSupabaseContext()
    const currentMapId = mapState.current
        ? mapState.current.metadata.id
        : fallbackMapId
    const [updateMetadataTimeout, setUpdateMetadataTimeout] =
        React.useState(null)
    const lastDatasetRef = React.useRef<HTMLDivElement>(null)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)

    // Fetch once in the beginning to make startup faster
    useEffect(() => {
        if (!mapState.current) {
            changeMap(fallbackMapId, initialViewState)
        }
    }, [mapState.current])

    useEffect(() => {
        // Fetch the data every five seconds
        const interval = setInterval(() => changeMap(currentMapId), 5000)
        return () => clearInterval(interval)
    }, [currentMapId])

    // Save the map state to local storage whenever it changes
    useEffect(() => {
        // Debounce with 1 second
        const timeout = setTimeout(() => {
            CacheManager.setItem(
                MAP_STATE_KEY,
                JSON.stringify({
                    schemaVersion: MAP_SCHEMA_VERSION,
                    state: mapState,
                })
            )
        }, 1000)
        return () => clearTimeout(timeout)
    }, [mapState])

    // Fetch userMapDatasets
    const changeMap = (mapId: string, initialViewState?: ViewState) => {
        fetchMapById(supabase.client, mapId)
            .then((data) => {
                // Inject local datas ets
                const localDatasets = loadLocalDatasetsForMap(currentMapId)
                const convertedLocalDatasets = localDatasets.map(
                    convertLocalDatasetToDataset
                )

                // Set initial visibility based on view state
                if (initialViewState?.visible) {
                    data.datasets = data.datasets.map((dataset) => {
                        const isVisible = initialViewState.visible.some(
                            (uuid) => dataset.id.startsWith(uuid)
                        )
                        return {
                            ...dataset,
                            isVisible,
                        }
                    })
                }
                data.datasets = [...data.datasets, ...convertedLocalDatasets]

                dispatch({ type: 'SET_CURRENT', map: data })
            })
            .catch((error) => {
                Sentry.captureException(error)
                setErrorDialogOpen(true)
            })
    }

    // Delete map
    const deleteMap = async (mapId: string) => {
        await deleteMapFromDatabase(supabase.client, mapId).catch((error) => {
            throw error
        })
        changeMap(fallbackMapId)
    }

    const removeDataset = async (id: string) => {
        // Opportunistically remove the dataset from the state first
        const datasetToRemove = mapState.current.datasets.find(
            (dataset) => dataset.id === id
        )
        dispatch({ type: 'REMOVE_DATASET_FROM_MAP', datasetVersionId: id })

        if (datasetToRemove.source != 'local') {
            await removeDatasetFromMap(supabase.client, currentMapId, id).catch(
                (error) => {
                    // Revert the change if it fails
                    dispatch({
                        type: 'ADD_DATASET_TO_MAP',
                        dataset: datasetToRemove,
                    })
                }
            )
        } else {
            // Remove the local dataset from local storage
            // The dataset id is the mapId + filename
            const localDataset = loadLocalDatasets().find(
                (d) => d.mapId + d.filename === id
            )
            removeLocalDataset(localDataset.mapId, localDataset.filename)
        }
    }

    const addDataset = async (dataset: Dataset) => {
        // Opportunistically add the dataset to the state first
        dispatch({ type: 'ADD_DATASET_TO_MAP', dataset: dataset })
        const newLayerIndex = mapState.current.datasets.length
        // Then add it to the database
        await addDatasetToMap(
            supabase.client,
            currentMapId,
            dataset.id,
            newLayerIndex
        ).catch((error) => {
            Sentry.captureException(error)
            // Revert the change if it fails
            dispatch({
                type: 'REMOVE_DATASET_FROM_MAP',
                datasetVersionId: dataset.id,
            })
        })
        lastDatasetRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const updateVizParams = async (
        dataset: Dataset,
        vizParams: VizParams,
        vizType: VizType
    ) => {
        const vizId = uuid()
        dispatch({
            type: 'UPDATE_VIZ_PARAMS',
            datasetVersionId: dataset.id,
            vizId: vizId,
            vizType: vizType,
            vizParams: vizParams,
        })
        if (dataset.source != 'local') {
            await updateVizParamsForMap(
                supabase.client,
                currentMapId,
                dataset.id,
                dataset.name,
                vizId,
                vizParams,
                vizType
            )
        }
    }

    const updateLayerOrder = async (
        layerOrder: {
            layerOrder: number
            datasetVersionId: string
        }[]
    ) => {
        await updateLayerOrderForMap(supabase.client, currentMapId, layerOrder)
    }

    const createNewMap = async () => {
        const newMapId = await createMap(supabase.client)
        changeMap(newMapId)
    }

    const updateMapMetadata = async (name: string, description: string) => {
        dispatch({
            type: 'UPDATE_MAP_METADATA',
            name: name,
            description: description,
        })
        // Debouncing database updates
        if (updateMetadataTimeout) {
            clearTimeout(updateMetadataTimeout)
        }
        const databaseUpdateTimout = setTimeout(async () => {
            await supabase.client
                .from('maps')
                .update({ name: name, description: description })
                .eq('id', currentMapId)
        }, 1000)
        setUpdateMetadataTimeout(databaseUpdateTimout)
    }

    const deleteDatasetFromCatalog = (datasetVersionId: string) => {
        dispatch({
            type: 'DELETE_DATASET_FROM_CATALOG',
            datasetVersionId: datasetVersionId,
        })
        deleteDataset(supabase.client, datasetVersionId)
    }

    const selectEarthEngineVisualization = (
        datasetVersionId: string,
        idx: number
    ) => {
        dispatch({
            type: 'SELECT_EARTH_ENGINE_VISUALIZATION',
            datasetVersionId: datasetVersionId,
            idx: idx,
        })
    }

    if (!mapState.current) {
        return <LoadingScreen />
    } else {
        return (
            <>
                <ErrorDialog
                    open={errorDialogOpen}
                    message="Sorry, we are having trouble loading your map. Please log in and try again."
                    onClose={() => setErrorDialogOpen(false)}
                    onLogout={supabase.logout}
                />
                <MapContext.Provider
                    value={{
                        state: mapState,
                        dispatch: dispatch,
                        createNewMap: createNewMap,
                        changeMap: changeMap,
                        deleteMap: deleteMap,
                        updateMapMetadata: updateMapMetadata,
                        addDatasetToMap: addDataset,
                        removeDatasetFromMap: removeDataset,
                        updateVizParams: updateVizParams,
                        updateLayerOrder: updateLayerOrder,
                        lastDatasetRef: lastDatasetRef,
                        deleteDatasetFromCatalog: deleteDatasetFromCatalog,
                        selectEarthEngineVisualization:
                            selectEarthEngineVisualization,
                    }}
                >
                    {children}
                </MapContext.Provider>
            </>
        )
    }
}

export default MapProvider
