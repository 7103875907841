import { Dataset } from '../types/dataset.ts'
import { PixelInfo } from '../components/PixelInfoView/PixelInfoView'
import {
    DEFAULT_DATA_REGION,
    GCP_MULTI_REGION_MAPPING,
    TILER_DEPLOYMENT_REGIONS,
} from '../constants.ts'
import { PseudocolorRasterVizParams } from 'types/viz.js'
import { isFastTiler } from '../utils'

function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
}

function getRegionFromDataset(dataset: Dataset): string {
    const environment = import.meta.env.EARTHSCALE_ENVIRONMENT
    // On localhost, we don't have regions
    let region = ''
    // On preview staging, we've only got our default deployment
    if (environment == 'preview' || environment === 'staging') {
        region = DEFAULT_DATA_REGION
    }
    // On production, we check whether we've got a regional deployment that fits, and
    // fall back to the default deployment if not
    if (environment === 'production') {
        let dataRegion = dataset.dataRegion
        // In the case of multi-regional buckets, we need to know which region to use
        if (GCP_MULTI_REGION_MAPPING[dataRegion]) {
            dataRegion = GCP_MULTI_REGION_MAPPING[dataRegion]
        }
        const weGotADeployment = TILER_DEPLOYMENT_REGIONS.includes(dataRegion)
        if (weGotADeployment) {
            region = dataRegion
        } else {
            region = DEFAULT_DATA_REGION
        }
    }
    return region
}

function fastTileServerURL(dataset: Dataset, user: { api_keys: string }) {
    const vizParams = dataset.vizParams as PseudocolorRasterVizParams
    const band = vizParams.band
    const minMaxesPerBand = vizParams.minMaxesPerBand[band]
    const rescale =
        minMaxesPerBand[0].toString() + ',' + minMaxesPerBand[1].toString()

    const params = new URLSearchParams()

    params.set('api_key', user.api_keys[0])
    params.set('variables', band)
    params.set('rescale', rescale)
    params.set('colormap_name', vizParams.colorRamp)

    const selectedDimensions = Object.entries(dataset.selectedDimensions)
        .map(([dim, index]) => `${dim}:${index}`)
        .join(',')
    if (selectedDimensions) {
        params.set('dimidx', selectedDimensions)
    }
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }
    const url =
        import.meta.env.EARTHSCALE_FAST_TILER_URL.replace(/\/$/, '') +
        region +
        `/tiles/raster/` +
        dataset.cacheKey +
        `/{z}/{x}/{y}.webp` +
        '?' +
        params.toString()
    return url
}

function createTileServerURL(dataset: Dataset, user?: { api_keys: string }) {
    const isFast = isFastTiler(dataset)
    if (isFast) {
        // FIXME: Drop the console log once things are working
        console.log('Using fast tiler')
        return fastTileServerURL(dataset, user)
    }

    const selectedDimensions = Object.entries(dataset.selectedDimensions)
        .map(([dim, index]) => `${encodeURIComponent(dim)}=${index}`)
        .join('&')
    const dimensionAddition =
        selectedDimensions.length > 0 ? '&' + selectedDimensions : ''

    let vizId: string | null = null

    if (
        dataset.className == 'EarthEngineDataset' &&
        dataset.earthEngineVisualizations != null &&
        dataset.earthEngineVisualizations.length > 0
    ) {
        // ensure idx is in range
        const selectedEarthEngineVisualizationIdx = Math.min(
            Math.max(dataset.selectedEarthEngineVisualizationIndex, 0),
            dataset.earthEngineVisualizations.length - 1
        )
        vizId =
            dataset.earthEngineVisualizations[
                selectedEarthEngineVisualizationIdx
            ].id
    } else {
        vizId = dataset.vizId
    }

    const vizParamsAddition = vizId ? '&viz_id=' + vizId : ''

    const keyAddition =
        user && user.api_keys.length > 0
            ? '&key=' + encodeURIComponent(user.api_keys[0])
            : ''

    const extension = dataset.type == 'raster' ? 'png' : 'mvt'
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }
    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        region +
        '/tiles/{z}/{x}/{y}.' +
        extension +
        '?dataset=' +
        dataset.id +
        dimensionAddition +
        vizParamsAddition +
        keyAddition
    return url
}

async function getPixelInfo(
    dataset: Dataset,
    lat: number,
    lon: number,
    accessToken: string
): Promise<PixelInfo> {
    const hasDimensions = Object.entries(dataset.selectedDimensions).length > 0
    const dimensions = hasDimensions
        ? '&' +
          Object.entries(dataset.selectedDimensions)
              .map(([dim, index]) => `${dim}=${index}`)
              .join('&')
        : ''
    let region = getRegionFromDataset(dataset)
    if (region != '') {
        region = '/' + region
    }

    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        region +
        '/tiles/pixel-info?dataset=' +
        dataset.id +
        '&lat=' +
        lat +
        '&lon=' +
        lon +
        dimensions

    const response = await fetch(url, {
        headers: {
            Authorization: accessToken,
        },
    })

    if (!response.ok && response.status !== 204) {
        return {
            datasetName: dataset.name,
            datasetType: dataset.type,
            json: {},
            error: `Failed to fetch pixel info: ${response.status} ${response.statusText}`,
        }
    }

    // if 204, return empty object
    if (response.status === 204) {
        const pixelInfo: PixelInfo = {
            datasetName: dataset.name,
            datasetType: dataset.type,
            json: {},
        }
        return pixelInfo
    }

    const pixelInfoJSON = await response.json()
    const pixelInfo: PixelInfo = {
        datasetName: dataset.name,
        datasetType: dataset.type,
        json: pixelInfoJSON,
    }
    return pixelInfo
}

export { createTileServerURL, getPixelInfo }
