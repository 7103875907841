import React, { useState } from 'react'
import { Button } from '@mui/material'
import { Public } from '@mui/icons-material'
import { useMapContext } from '../../context/map/mapContext.ts'

const GlobeViewButton: React.FC = () => {
    const { dispatch } = useMapContext()

    return (
        <Button
            variant="outlined"
            size="small"
            startIcon={<Public />}
            sx={{
                borderRadius: '20px',
                backgroundColor: 'var(--panel-background)',
                color: 'white',
                borderColor: 'rgba(230, 246, 231, 0.5)',
                '&:hover': {
                    borderRadius: 'var(--borderRadius, 20px)',
                    border: '1px solid rgba(230, 246, 231, 0.65)',
                    background: 'rgba(39, 46, 45, 0.7)',
                    backdropFilter: 'blur(8px)',
                },
                padding: '8px 16px',
                transition: 'all 0.3s ease',
            }}
            onClick={() => dispatch({ type: 'TOGGLE_GLOBE_VIEW' })}
        >
            Toggle Globe
        </Button>
    )
}

export default React.memo(GlobeViewButton, () => true)
