import * as React from 'react'
import Box from '@mui/material/Box'
import { Map as MapGLMap, Source } from 'react-map-gl'
import MapboxSatellite from '../../components/MapView/MapboxSatellite'
import MagicStyles from './MagicSearch.module.css'
import { Button, Pagination, Paper, Stack, useTheme } from '@mui/material'
import SearchBar from '../SearchBar/SearchBar.tsx'
import { viewport } from '@placemarkio/geo-viewport'
import logo_light from '../../assets/earthscale-logo-light.svg'
import LoginStyles from '../../pages/Login/Login.module.css'
import { setUser } from '@sentry/react'
import Chat, { ChatMessage } from '../Chat/Chat.tsx'
import datasetInfo from '../../assets/testData/subset.json'
import DatasetSearchListItem, {
    DatasetSearchResult,
} from './DatasetSearchResult.tsx'
import { ChatRequest, ChatResponse } from '../../types/chat.ts'
import { useMapContext } from '../../context/map/mapContext.ts'
import { fetchGoogleDriveFolders } from '../../api/googleDrive.ts'
import { fetchDatasets } from '../../api/dataset.ts'
import { useEffect } from 'react'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import { boundsStringToArray } from '../../utils.tsx'
import Sidebar from '../Sidebar/Sidebar.tsx'
import { Dataset } from '../../types/dataset'
import { chat } from '../../api/chat'

const startLocations = [
    {
        latitude: 38.6559,
        longitude: -101.9236,
        zoom: 12,
    },
    {
        latitude: 35.615,
        longitude: 139.653,
        zoom: 12,
    },
    {
        latitude: 46.44,
        longitude: 7.747,
        zoom: 12,
    },
    {
        latitude: 25.636,
        longitude: 10.077,
        zoom: 12,
    },
    {
        latitude: 64.46217212812809,
        longitude: 10.74625177968295,
        zoom: 12,
    },
    {
        latitude: 3.7440868431601615,
        longitude: -60.2883514844536,
        zoom: 12,
    },
    {
        latitude: 22.559,
        longitude: 32.314,
        zoom: 12,
    },
]

const randomStartLocation =
    startLocations[Math.floor(Math.random() * startLocations.length)]

function wrapLongitude(longitude: number): number {
    if (longitude > 180) {
        return longitude - 360
    }
    if (longitude < -180) {
        return longitude + 360
    }
    return longitude
}

function wrapLatitude(latitude: number): number {
    if (latitude > 90) {
        return latitude - 180
    }
    if (latitude < -90) {
        return latitude + 180
    }
    return latitude
}

const exampleQueries = [
    'Find datasets suitable to analyze deforestation in the Amazon Rainforest',
    'Find datasets on global forest cover',
    'Find datasets on carbon storage in forests',
]

const boundsOfBrazil = [-73.982, -33.768, -34.729, 5.244]

function MagicSearch() {
    const theme = useTheme()
    const supabaseContext = useSupabaseContext()
    const { dispatch, addDatasetToMap } = useMapContext()

    const mapRef = React.useRef(null)
    const timeoutRef = React.useRef(null)

    const [position, setPosition] = React.useState({
        bottom: 'calc(100vh / 2)',
        left: '50%',
        transform: 'translate(-50%, 0)',
    })
    const [size, setSize] = React.useState({ width: '700px' })

    const [searchTerm, setSearchTerm] = React.useState('')
    const [placeholder, setPlaceholder] = React.useState('')
    const [placeholdersEnabled, setPlaceholdersEnabled] = React.useState(true)
    const [exampleQueryIndex, setExampleQueryIndex] = React.useState(0)

    const [queryIsRunning, setQueryIsRunning] = React.useState(false)
    const [submittedAtLeastOneRequest, setSubmittedAtLeastOneRequest] =
        React.useState(false)

    const [chatRequests, setChatRequests] = React.useState<ChatRequest[]>([])
    const [chatId, setChatId] = React.useState<string>(crypto.randomUUID())
    const [chatResponses, setChatResponses] = React.useState<ChatResponse[]>([])
    const [searchResultPage, setSearchResultPage] = React.useState(0)

    const [autoMoveMap, setAutoMoveMap] = React.useState(true)

    const [firstDatasetAdded, setFirstDatasetAdded] = React.useState(false)

    // Add new state for tracking dataset interactions
    const [upvotedDatasets, setUpvotedDatasets] = React.useState<string[]>([])
    const [downvotedDatasets, setDownvotedDatasets] = React.useState<string[]>(
        []
    )
    const [addedDatasets, setAddedDatasets] = React.useState<string[]>([])
    // Reset all chat state when the chat ID changes
    React.useEffect(() => {
        setChatRequests([])
        setChatResponses([])
        setSubmittedAtLeastOneRequest(false)
        setSearchResultPage(0)
    }, [chatId])

    const startNewChat = () => {
        setChatId(crypto.randomUUID())
        setUpvotedDatasets([])
        setDownvotedDatasets([])
        setAddedDatasets([])
    }

    // FIXME: START OF THE HACK
    const fetchCatalog = async () => {
        fetchDatasets(supabaseContext.client).then((datasets) => {
            dispatch({
                type: 'SET_CATALOG',
                datasets: datasets,
            })
        })
    }

    // Fetch once at the start
    useEffect(() => {
        fetchCatalog()
    }, [])

    const { state } = useMapContext()

    const chatMessages = chatRequests.flatMap((request, index) => {
        const requestMessage = {
            text: chatRequests[index].message,
            sender: 'myself',
        }
        // There might not be a response yet
        const response = chatResponses[index]
        let responseText = 'Thinking ...'
        if (response) {
            responseText = response.responseText
        }
        const responseMessage = {
            text: responseText,
            sender: 'thirdParty',
        }
        return [requestMessage, responseMessage]
    })

    const handleAdd = (dataset: Dataset) => {
        setPosition({
            bottom: null,
            top: '8px',
            left: '8px',
            transform: null,
        })
        setSize({ width: '400px' })
        const datasetToAdd = {
            ...dataset,
            isVisible: true,
        }

        addDatasetToMap(datasetToAdd)
        setAddedDatasets((prev) => [...prev, dataset.id])

        if (!firstDatasetAdded) {
            setAutoMoveMap(false)
            const bounds = boundsStringToArray(datasetToAdd.extent)
            flyToBounds(bounds)
            setFirstDatasetAdded(true)
        }
    }

    const handleSubmit = async () => {
        setQueryIsRunning(true)
        setPlaceholder('Refine your search')
        setSubmittedAtLeastOneRequest(true)

        const accessToken = supabaseContext.session?.access_token
        const chatRequest: ChatRequest = {
            message: searchTerm,
            addedDatasetIds: addedDatasets,
            upvotedDatasetIds: upvotedDatasets,
            downvotedDatasetIds: downvotedDatasets,
            chatId: chatId,
        }
        setChatRequests([...chatRequests, chatRequest])
        setSearchTerm('')

        try {
            const chatResponse = await chat(accessToken, chatRequest)

            // Find the matching datasets from the catalog
            const allDatasets = [...state.current.datasets, ...state.catalog]
            const matchingDatasets = chatResponse.datasetIds
                .map((id, index) => {
                    if (!state.catalog || state.catalog.length == 0) {
                        return null
                    }

                    const dataset = allDatasets.find((d) => d.id === id)
                    if (!dataset) {
                        console.error('No matching dataset found for', id)
                        return null
                    }

                    return {
                        aiSummary: chatResponse.explanations[index],
                        dataset: dataset,
                    }
                })
                .filter(Boolean)

            // TODO: Add support for pinned and removed datasets
            if (!firstDatasetAdded) {
                setPosition({ ...position, bottom: 'calc(100vh / 2 - 450px)' })
            }
            setQueryIsRunning(false)
            setChatResponses([...chatResponses, chatResponse])
            setSearchResultPage(chatResponses.length + 1)
        } catch (error) {
            console.error('Search failed:', error)
            // TODO: Add error handling UI
        }
    }

    const flyToBounds = (bounds: [number, number, number, number]) => {
        if (mapRef.current) {
            const map = mapRef.current
            const canvas = map.getCanvas()

            // Some padding so you can see the bounds
            const vp = viewport(bounds, [
                parseInt(canvas.style['width']),
                parseInt(canvas.style['height']),
            ])
            mapRef.current.flyTo({
                center: vp.center,
                zoom: vp.zoom,
                duration: 3000,
                essential: true,
            })
        }
    }

    React.useEffect(() => {
        const typeMessage = (characterIndex) => {
            if (!placeholdersEnabled) {
                setPlaceholder('')
                return
            }
            if (characterIndex < exampleQueries[exampleQueryIndex].length) {
                setPlaceholder(
                    exampleQueries[exampleQueryIndex].slice(
                        0,
                        characterIndex + 1
                    )
                )
                const randomInterval = Math.random() * 10 + 40
                timeoutRef.current = setTimeout(() => {
                    typeMessage(characterIndex + 1)
                }, randomInterval)
            } else {
                timeoutRef.current = setTimeout(() => {
                    setPlaceholder('')
                    setExampleQueryIndex(
                        (exampleQueryIndex + 1) % exampleQueries.length
                    )
                }, 2000) // Pause before switching to the next message
            }
        }
        typeMessage(0)
        return () => clearInterval(timeoutRef.current)
    }, [exampleQueryIndex, placeholdersEnabled])

    // Move login map
    const distancePerSecond = 0.001
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (mapRef.current === null || !autoMoveMap) {
                return
            }
            const center = mapRef.current.getCenter()
            center.lng = wrapLongitude(center.lng + distancePerSecond)
            center.lat = wrapLatitude(center.lat + distancePerSecond)
            mapRef.current.easeTo({
                center,
                essential: true,
                duration: 1000,
                easing: (n) => n,
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [mapRef, autoMoveMap])

    const handleUpvote = (datasetId: string) => {
        setUpvotedDatasets((prev) =>
            prev.includes(datasetId)
                ? prev.filter((id) => id !== datasetId)
                : [...prev, datasetId]
        )
        // Remove from downvoted if it exists there
        setDownvotedDatasets((prev) => prev.filter((id) => id !== datasetId))
    }

    const handleDownvote = (datasetId: string) => {
        setDownvotedDatasets((prev) =>
            prev.includes(datasetId)
                ? prev.filter((id) => id !== datasetId)
                : [...prev, datasetId]
        )
        // Remove from upvoted if it exists there
        setUpvotedDatasets((prev) => prev.filter((id) => id !== datasetId))
    }

    return (
        <>
            {firstDatasetAdded && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        width: '400px',
                        height: '500px',
                    }}
                >
                    <Sidebar
                        flyToDatasetBounds={flyToBounds}
                        maxHeight={'500px'}
                    />
                </Box>
            )}
            <Box className={MagicStyles.map}>
                <MapGLMap
                    initialViewState={randomStartLocation}
                    ref={mapRef}
                    interactive={true}
                    attributionControl={true}
                    mapboxAccessToken={import.meta.env.EARTHSCALE_MAPBOX_TOKEN}
                    logoPosition={'bottom-left'}
                >
                    <MapboxSatellite />
                </MapGLMap>
            </Box>
            <Paper
                className={MagicStyles.searchBarBox}
                style={{
                    bottom: position.bottom,
                    top: position.top,
                    left: position.left,
                    transform: position.transform,
                    width: size.width,
                    transition: 'top 0.5s, left 0.5s, width 0.5s',
                }}
            >
                <Stack direction="column" spacing={1}>
                    <img
                        src={logo_light as string}
                        alt="Logo"
                        className={LoginStyles.logo}
                    />
                    {submittedAtLeastOneRequest && (
                        <Box className={MagicStyles.chatBox}>
                            <Chat messages={chatMessages} />
                        </Box>
                    )}
                    {submittedAtLeastOneRequest && (
                        <Button
                            onClick={startNewChat}
                            variant="outlined"
                            size="small"
                            sx={{ alignSelf: 'flex-end' }}
                        >
                            Start New Chat
                        </Button>
                    )}
                    <SearchBar
                        placeholder={placeholder}
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                        onSubmit={handleSubmit}
                        onClick={() => {
                            setPlaceholdersEnabled(false)
                            setPlaceholder('')
                            clearTimeout(timeoutRef.current)
                        }}
                        showSearchIcon={!submittedAtLeastOneRequest}
                        disabled={queryIsRunning}
                    />
                    {chatResponses.length > 0 && (
                        <Box className={MagicStyles.searchResults}>
                            <Stack direction="column" spacing={1}>
                                <Pagination
                                    count={chatResponses.length}
                                    size={'small'}
                                    page={searchResultPage}
                                    onChange={(event, value) => {
                                        setSearchResultPage(value)
                                    }}
                                />
                                {chatResponses[
                                    searchResultPage - 1
                                ].datasetIds.map((id, index) => {
                                    if (
                                        !state.catalog ||
                                        state.catalog.length == 0
                                    ) {
                                        return null
                                    }
                                    const allDatasets = [
                                        ...state.current.datasets,
                                        ...state.catalog,
                                    ]
                                    const dataset = allDatasets.find(
                                        (d) => d.id === id
                                    )
                                    if (!dataset) {
                                        console.error(id)
                                        return null
                                    }
                                    return (
                                        <DatasetSearchListItem
                                            result={{
                                                dataset,
                                                aiSummary:
                                                    chatResponses[
                                                        searchResultPage - 1
                                                    ].explanations[index],
                                            }}
                                            onAddToMap={handleAdd}
                                            onUpvote={() =>
                                                handleUpvote(dataset.id)
                                            }
                                            onDownvote={() =>
                                                handleDownvote(dataset.id)
                                            }
                                            isUpvoted={upvotedDatasets.includes(
                                                dataset.id
                                            )}
                                            isDownvoted={downvotedDatasets.includes(
                                                dataset.id
                                            )}
                                            key={dataset.id}
                                        />
                                    )
                                })}
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </Paper>
        </>
    )
}

export default MagicSearch
