import React, { useEffect, useState } from 'react'
import {
    Chip,
    Collapse,
    Divider,
    Icon,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'

import DatasetCatalogStyle from './DatasetCatalog.module.css'
import {
    Add,
    CenterFocusStrong,
    ChevronRight,
    ExpandMore,
    Remove,
} from '@mui/icons-material'
import { Dataset } from '../../types/dataset.ts'
import Box from '@mui/material/Box'
import { fetchDatasets, subscribeToDatasetChanges } from '../../api/dataset.ts'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import DatasetList from '../DatasetList/DatasetList.tsx'
import { useMapContext } from '../../context/map/mapContext.ts'
import SearchBar from '../SearchBar/SearchBar.tsx'
import {
    fetchGoogleDriveFiles,
    fetchGoogleDriveFolders,
    filterFolderByDatasetIds,
} from '../../api/googleDrive.ts'
import { GoogleDriveCatalog } from './GoogleDriveCatalog.tsx'
import { filterDatasetsInView } from '../../utils'

interface DatasetCatalogProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    maxHeight: string | null
    flyToDatasetBounds: (dataset: Dataset) => void
    mapRef: React.RefObject<MapRef>
}

interface InternalCatalogProps {
    sectionText: string
    secondaryAction: React.ReactNode
    datasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
    startOpen?: boolean
}

const InternalCatalog = ({
    sectionText,
    secondaryAction,
    datasets,
    flyToDatasetBounds,
    startOpen = true,
}: InternalCatalogProps) => {
    const [open, setOpen] = React.useState(startOpen)
    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <List disablePadding>
            <ListItem dense disablePadding secondaryAction={secondaryAction}>
                <ListItemButton
                    onClick={handleClick}
                    sx={{ borderRadius: '8px', padding: '8px' }}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '0px',
                            paddingRight: '6px',
                            paddingBottom: '4px',
                        }}
                    >
                        {open ? <ExpandMore /> : <ChevronRight />}
                    </ListItemIcon>
                    <ListItemText primary={sectionText} />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ marginLeft: '10px' }}>
                    <DatasetList
                        datasets={datasets}
                        flyToDatasetBounds={flyToDatasetBounds}
                        showVizSettings={false}
                        showSelector={true}
                        showRemoveFromMap={false}
                        showVisibility={false}
                        disableNonActive={false}
                        reorderable={false}
                    />
                </Box>
            </Collapse>
        </List>
    )
}

type ChipFilterProps = {
    filter: string | null
    onFilterSelect: (filter: string) => void
}

const statusToFilterName = {
    visible: 'Visible',
    ready: 'Ready',
    processing: 'Processing',
    processing_failed: 'Failed',
}

const ChipFilters = ({ filter, onFilterSelect }) => {
    return (
        <Box className={DatasetCatalogStyle.chipBox}>
            {!filter && (
                <>
                    {Object.keys(statusToFilterName).map((status) => (
                        <Chip
                            key={status}
                            label={statusToFilterName[status]}
                            onClick={() => onFilterSelect(status)}
                            className={DatasetCatalogStyle.chip}
                            size="small"
                        />
                    ))}
                </>
            )}
            {filter && (
                <Chip
                    label={statusToFilterName[filter]}
                    onDelete={() => onFilterSelect(null)}
                    className={DatasetCatalogStyle.chip}
                    size="small"
                    color="primary"
                />
            )}
        </Box>
    )
}

const DatasetCatalog = ({
    isOpen,
    setIsOpen,
    maxHeight,
    flyToDatasetBounds,
    mapRef,
}: DatasetCatalogProps) => {
    const supabaseContext = useSupabaseContext()
    const [searchTerm, setSearchTerm] = useState('')
    const [statusFilter, setStatusFilter] = useState(null)
    const [googleDriveFolders, setGoogleDriveFolders] = useState<
        GoogleDriveFolder[]
    >([])
    const theme = useTheme()
    const { state, dispatch } = useMapContext()
    const [filterToView, setFilterToView] = useState(false)
    const height = isOpen ? maxHeight : null

    React.useEffect(() => {
        subscribeToDatasetChanges(supabaseContext.client, (payload) => {
            fetchGoogleDriveFolders(supabaseContext.client).then((folders) => {
                setGoogleDriveFolders(folders)
            })
        })
    }, [])

    // Sort datasets to first show ones with status "processing_failed", then ones with
    // status "processing" and finally, the rest sorted alphabetically
    const datasetsToShow = [...state.catalog].sort((a, b) => {
        if (
            a.status === 'processing_failed' &&
            b.status !== 'processing_failed'
        ) {
            return -1
        }
        if (
            a.status !== 'processing_failed' &&
            b.status === 'processing_failed'
        ) {
            return 1
        }
        if (a.status === 'processing' && b.status !== 'processing') {
            return -1
        }
        if (a.status !== 'processing' && b.status === 'processing') {
            return 1
        }
        return a.name.localeCompare(b.name)
    })

    let filteredDatasets = datasetsToShow.filter((dataset) => {
        let showDataset = true
        if (statusFilter) {
            if (statusFilter === 'visible' && dataset.isVisible) {
                showDataset = true
            } else if (dataset.status === statusFilter) {
                showDataset = true
            } else {
                showDataset = false
            }
        }
        return (
            showDataset &&
            dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    })

    if (filterToView && mapRef.current) {
        const bounds = mapRef.current.getBounds()
        const viewportBounds: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ]
        filteredDatasets = filterDatasetsInView(
            filteredDatasets,
            viewportBounds
        )
    }

    // Recent datasets are ones which have been updated in the past 5 minutes
    const recentlyUpdatedDatasets = filteredDatasets.filter((dataset) => {
        return (
            new Date(dataset.updatedAt) > new Date(Date.now() - 5 * 60 * 1000)
        )
    })

    const internalCatalogDatasets = filteredDatasets.filter((dataset) => {
        return dataset.source === 'internal_catalog'
    })
    const googleDriveDatasets = filteredDatasets.filter((dataset) => {
        return dataset.source === 'google_drive'
    })
    const googleDriveDatasetIds = googleDriveDatasets.map(
        (dataset) => dataset.datasetId
    )
    const filteredGoogleDriveFolders = googleDriveFolders.map((folder) => {
        return filterFolderByDatasetIds(folder, googleDriveDatasetIds)
    })

    const secondaryHeaderText = isOpen ? null : 'Add datasets to your map'
    const datasetListMaxHeight = maxHeight ? `calc(${maxHeight} - 130px)` : null

    const showGoogleDriveCatalog =
        import.meta.env.EARTHSCALE_FEATURE_FLAG_GOOGLE_DRIVE == 'true'

    return (
        <Paper
            className={DatasetCatalogStyle.datasetCatalog}
            sx={{
                height: height,
                maxHeight: maxHeight,
            }}
        >
            <List className={DatasetCatalogStyle.header} dense disablePadding>
                <ListItemButton
                    dense
                    sx={{ borderRadius: '8px', padding: '8px' }}
                    onClick={() => {
                        if (isOpen) {
                            dispatch({ type: 'HIDE_ALL_CATALOG_DATASETS' })
                        }
                        setIsOpen((value) => !value)
                    }}
                >
                    <ListItemText
                        primary="Dataset Browser"
                        secondary={secondaryHeaderText}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondaryTypographyProps={{ variant: 'body2' }}
                    />
                    <Icon>{isOpen ? <Remove /> : <Add />}</Icon>
                </ListItemButton>
            </List>
            {isOpen && (
                <>
                    <Box sx={{ marginTop: '8px' }} />
                    <Stack direction="row" spacing={1}>
                        <SearchBar
                            searchTerm={searchTerm}
                            onSearchChange={setSearchTerm}
                        />
                        <Tooltip
                            title={
                                filterToView
                                    ? 'Toggle to show all datasets'
                                    : 'Toggle to only show datasets in current view'
                            }
                        >
                            <IconButton
                                onClick={() => setFilterToView(!filterToView)}
                                color={filterToView ? 'secondary' : 'primary'}
                                size="small"
                            >
                                <CenterFocusStrong />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <ChipFilters
                        filter={statusFilter}
                        onFilterSelect={setStatusFilter}
                    />
                    <Divider />
                    <Box
                        sx={{
                            maxHeight: datasetListMaxHeight,
                            overflowY: 'auto',
                        }}
                        className={DatasetCatalogStyle.datasetList}
                    >
                        {recentlyUpdatedDatasets.length > 0 && (
                            <InternalCatalog
                                sectionText={'Recently added or updated'}
                                secondaryAction={null}
                                datasets={recentlyUpdatedDatasets}
                                flyToDatasetBounds={flyToDatasetBounds}
                                startOpen={true}
                            />
                        )}
                        <InternalCatalog
                            sectionText="Catalog"
                            secondaryAction={
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Link
                                        href="https://earthscale-ai-earthscale-sdk-demo.readthedocs-hosted.com/en/latest/"
                                        color="inherit"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        How to add datasets
                                    </Link>
                                </Typography>
                            }
                            datasets={internalCatalogDatasets}
                            flyToDatasetBounds={flyToDatasetBounds}
                            startOpen={!showGoogleDriveCatalog}
                        />
                        {showGoogleDriveCatalog && (
                            <GoogleDriveCatalog
                                datasets={googleDriveDatasets}
                                googleDriveFolders={filteredGoogleDriveFolders}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        )}
                    </Box>
                </>
            )}
        </Paper>
    )
}

export default DatasetCatalog
