import * as React from 'react'
import Joyride, {
    CallBackProps,
    STATUS,
    Step,
    TooltipRenderProps,
} from 'react-joyride'
import {
    Link,
    useTheme,
    Box,
    Button,
    Typography,
    styled as muiStyled,
} from '@mui/material'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import eyeOpen from '../../assets/icons/eye-open.svg'
import { updateOnboardingCompleted } from '../../api/user'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
const EXAMPLE_ZARR_URL = 'gs://earthscale-demo/sample/hls_tanzania.zarr'
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined'
import { Add } from '@mui/icons-material'
const TooltipContainer = muiStyled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    maxWidth: 320,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
}))

const TooltipFooter = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
}))

const ButtonGroup = muiStyled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}))

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const PulseButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: ${pulse} 2s infinite;
    position: fixed;
    left: 50vw;
    top: 65vh;
    z-index: 10001;
    pointer-events: none;
`

function CustomTooltip(props: TooltipRenderProps) {
    const {
        backProps,
        closeProps,
        continuous,
        index,
        primaryProps,
        skipProps,
        step,
        tooltipProps,
    } = props

    return (
        <TooltipContainer {...tooltipProps}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {step.title && (
                    <Typography variant="h6" component="h4">
                        {step.title}
                    </Typography>
                )}
            </Box>

            <Typography
                variant="body2"
                sx={{
                    my: 2,
                    alignSelf: 'center',
                    color: '#f0f0f0 !important',
                }}
            >
                {step.content}
            </Typography>

            <TooltipFooter>
                <Button variant="outlined" size="small" {...skipProps}>
                    {skipProps.title}
                </Button>
                <ButtonGroup>
                    {index > 0 && (
                        <Button variant="text" size="small" {...backProps}>
                            {backProps.title}
                        </Button>
                    )}
                    {continuous && (
                        <Button
                            variant="contained"
                            size="small"
                            {...primaryProps}
                        >
                            {primaryProps.title}
                        </Button>
                    )}
                </ButtonGroup>
            </TooltipFooter>
        </TooltipContainer>
    )
}

export default function OnboardingTour() {
    const theme = useTheme()
    const [run, setRun] = React.useState(true)
    const [pulseButtonVisible, setPulseButtonVisible] = React.useState(false)
    const { client: supabaseClient } = useSupabaseContext()
    const steps: Step[] = [
        {
            target: 'body',
            content:
                'Welcome to Earthscale! Would you like a brief overview of the platform?',
            placement: 'center',
            disableCloseOnEsc: true,
        },
        {
            target: '#datasetCatalog',
            content:
                'The dataset browser contains a list of geospatial datasets available across your organization.',
            placement: 'right',
            title: 'Dataset Browser',
            disableCloseOnEsc: true,
        },
        {
            target: '#mapDatasets',
            content: (
                <>
                    A map is a collection of datasets. From the browser, click
                    the{' '}
                    <Add style={{ height: '.8em', verticalAlign: 'middle' }} />{' '}
                    icon to add datasets to your map. You can also share maps
                    across your organization.
                </>
            ),
            placement: 'right',
            title: 'Map View',
            disableCloseOnEsc: true,
        },
        {
            target: '#firstDataset',
            content: (
                <>
                    Click a dataset card to zoom to its bounds and view details
                    like metadata or a tile server URL.
                    <br />
                    <br />
                    The{' '}
                    <img
                        src={eyeOpen}
                        alt="eye"
                        style={{ height: '.8em', verticalAlign: 'middle' }}
                    />
                    {'  '}
                    icon toggles the dataset's visibility.
                    <br />
                    <br />
                    Click the{' '}
                    <ColorLensOutlinedIcon
                        style={{ height: '.8em', verticalAlign: 'middle' }}
                    />
                    {'  '}
                    icon to edit the dataset's visualization parameters.
                    <br />
                    <br />
                    Drag and drop a card to reorder the layers in the map.
                </>
            ),
            placement: 'right',
            title: 'Dataset Info',
            disableCloseOnEsc: true,
        },
        {
            target: 'body',
            content:
                'Click anywhere on the map to see information about that location. It will show feature values at that point for all visible layers.',
            placement: 'center',
            styles: {
                tooltip: {
                    position: 'relative',
                    overflow: 'visible',
                },
            },
            title: 'Pixel Info',
            disableOverlay: true,
            disableOverlayClose: true,
            disableCloseOnEsc: true,
        },
        {
            target: '#addDatasetButton',
            content: (
                <>
                    Add datasets through the 'add dataset' button. If you'd
                    like, you can add this demo dataset:{' '}
                    <Link
                        href={EXAMPLE_ZARR_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {EXAMPLE_ZARR_URL}
                    </Link>
                </>
            ),
            placement: 'bottom',
            title: 'Add Dataset',
            disableOverlay: true,
            disableOverlayClose: true,
            disableCloseOnEsc: true,
        },
        {
            target: 'body',
            content: (
                <>
                    To learn about additional features, such as our Python SDK,
                    supported dataset types, tile servers, or permissions
                    management, please visit our{' '}
                    <Link
                        href="https://earthscale-ai-earthscale-sdk-demo.readthedocs-hosted.com/en/latest/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        documentation
                    </Link>
                    .
                    <br />
                    <br />
                    If you have any questions, feel free to reach out to us
                    directly via Slack or email.
                </>
            ),
            placement: 'center',
            disableCloseOnEsc: true,
        },
    ]

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, index } = data

        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED].includes(status)) {
            updateOnboardingCompleted(supabaseClient, true)
            setRun(false)
        }
        if (status === STATUS.RUNNING && steps[index].title === 'Pixel Info') {
            setPulseButtonVisible(true)
        } else {
            setPulseButtonVisible(false)
        }
    }

    return (
        <>
            {run && pulseButtonVisible && <PulseButton />}
            <Joyride
                steps={steps}
                run={run}
                continuous
                showProgress
                showSkipButton
                callback={handleJoyrideCallback}
                tooltipComponent={CustomTooltip}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                floaterProps={{
                    styles: {
                        arrow: {
                            length: 16,
                            spread: 12,
                        },
                        container: {
                            filter: 'drop-shadow(0 0 6px rgba(0,0,0,0.1))',
                        },
                    },
                }}
                locale={{
                    skip: 'Skip Tour',
                    back: 'Back',
                    next: 'Next',
                    last: 'Done',
                }}
            />
        </>
    )
}
