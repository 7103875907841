import { Dataset } from '../types/dataset'
import { LocalDataset } from '../types/localDataset'
import { LOCAL_DATASETS_LOCAL_STORAGE_KEY } from '../constants'
import CacheManager from '../context/cache'
import { VectorVizParams } from 'types/viz'
import {
    GeoJsonFeatureCollectionWithCrs,
    GeoJsonWithCrs,
} from '../types/geojson'
import proj4 from 'proj4'

function loadLocalDatasets(): LocalDataset[] {
    const localDatasets = CacheManager.getItem(LOCAL_DATASETS_LOCAL_STORAGE_KEY)
    if (localDatasets) {
        return JSON.parse(localDatasets)
    }
    return []
}

function loadLocalDatasetsForMap(mapId: string): LocalDataset[] {
    return loadLocalDatasets().filter((d) => d.mapId === mapId)
}

function addLocalDataset(dataset: LocalDataset) {
    const localDatasets = loadLocalDatasets()
    // Check whether a dataset with the same ID and filename already exists, if so
    // don't add it
    const exists = localDatasets.some(
        (d) => d.mapId === dataset.mapId && d.filename === dataset.filename
    )
    if (!exists) {
        localDatasets.push(dataset)
    }
    CacheManager.setItem(
        LOCAL_DATASETS_LOCAL_STORAGE_KEY,
        JSON.stringify(localDatasets)
    )
}

function computeBounds(
    geometry: GeoJSON.Geometry
): [number, number, number, number] {
    if (geometry.type === 'GeometryCollection') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.geometries.forEach((g) => {
            const b = computeBounds(g)
            minX = Math.min(minX, b[0])
            minY = Math.min(minY, b[1])
            maxX = Math.max(maxX, b[2])
            maxY = Math.max(maxY, b[3])
        })
        return [minX, minY, maxX, maxY]
    }

    let coords = []

    if (geometry.type === 'Point') {
        coords.push(geometry.coordinates[0])
    } else if (geometry.type === 'LineString') {
        geometry.coordinates.forEach((coord) => {
            coords.push(coord)
        })
    } else if (geometry.type === 'Polygon') {
        geometry.coordinates[0].forEach((coord) => {
            coords.push(coord)
        })
    } else if (geometry.type === 'MultiPoint') {
        geometry.coordinates.forEach((coord) => {
            coords.push(coord)
        })
    } else if (geometry.type === 'MultiLineString') {
        geometry.coordinates.forEach((lineString) => {
            lineString.forEach((coord) => {
                coords.push(coord)
            })
        })
    } else if (geometry.type === 'MultiPolygon') {
        geometry.coordinates.forEach((polygon) => {
            // Handle case where polygon is [Array(N)] vs Array(N)
            const points = Array.isArray(polygon[0]) ? polygon[0] : polygon
            points.forEach((coord) => {
                coords.push(coord)
            })
        })
    } else {
        throw new Error(`Unsupported geometry type: ${geometry}`)
    }

    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity
    for (let i = 0; i < coords.length; i += 2) {
        const coord = coords[i]
        if (isFinite(coord[0]) && isFinite(coord[1])) {
            minX = Math.min(minX, coord[0])
            minY = Math.min(minY, coord[1])
            maxX = Math.max(maxX, coord[0])
            maxY = Math.max(maxY, coord[1])
        }
    }
    return [minX, minY, maxX, maxY]
}

function computeBoundsForFeatureCollection(
    featureCollection: GeoJsonFeatureCollectionWithCrs
): [number, number, number, number] {
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity
    featureCollection.features.forEach((feature) => {
        if (feature.geometry) {
            const bounds = computeBounds(feature.geometry)
            minX = Math.min(minX, bounds[0])
            minY = Math.min(minY, bounds[1])
            maxX = Math.max(maxX, bounds[2])
            maxY = Math.max(maxY, bounds[3])
        }
    })
    return [minX, minY, maxX, maxY]
}

async function lookupGeoJsonCrsAsync(
    accessToken: string,
    geojson: GeoJsonWithCrs
): Promise<string> {
    if (!geojson.crs) {
        return '+proj=longlat +datum=WGS84 +no_defs +type=crs'
    }
    // if its given, we'll ask the backend to convert it to proj4
    const backendUrl = import.meta.env.EARTHSCALE_BACKEND_URL as string
    const strippedBackendUrl = backendUrl.replace(/\/+$/, '')
    const response = await fetch(
        `${strippedBackendUrl}/utils/geojson_crs_to_proj4`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ crs: geojson.crs }),
        }
    )
    const data = await response.json()
    return data.proj4
}

function reprojectGeoJsonGeometry(
    geom: GeoJSON.Geometry,
    reproject_func: (coord: number[]) => number[]
): GeoJSON.Geometry {
    switch (geom.type) {
        case 'Point':
            return {
                ...geom,
                coordinates: reproject_func(geom.coordinates),
            }
        case 'LineString':
            return {
                ...geom,
                coordinates: geom.coordinates.map((coord) =>
                    reproject_func(coord)
                ),
            }
        case 'Polygon':
            return {
                ...geom,
                // nested coordinates
                coordinates: geom.coordinates.map((ring) =>
                    ring.map((coord) => reproject_func(coord))
                ),
            }
        case 'MultiPoint':
            return {
                ...geom,
                coordinates: geom.coordinates.map((coord) =>
                    reproject_func(coord)
                ),
            }
        case 'MultiLineString':
            return {
                ...geom,
                coordinates: geom.coordinates.map((lineString) =>
                    lineString.map((coord) => reproject_func(coord))
                ),
            }
        case 'MultiPolygon':
            return {
                ...geom,
                // double nested coordinates
                coordinates: geom.coordinates.map((polygon) =>
                    polygon.map((ring) =>
                        ring.map((coord) => reproject_func(coord))
                    )
                ),
            }
        case 'GeometryCollection':
            return {
                ...geom,
                geometries: geom.geometries.map((g) =>
                    reprojectGeoJsonGeometry(g, reproject_func)
                ),
            }
    }
}

function reprojectGeoJson(
    geojson: GeoJsonFeatureCollectionWithCrs,
    crsProj4String: string
): GeoJSON.FeatureCollection {
    const reproject = proj4(
        crsProj4String,
        '+proj=longlat +datum=WGS84 +no_defs +type=crs'
    ).forward
    return {
        ...geojson,
        features: geojson.features.map((feature) => ({
            ...feature,
            geometry: reprojectGeoJsonGeometry(feature.geometry, reproject),
        })),
    }
}

function convertLocalDatasetToDataset(localDataset: LocalDataset): Dataset {
    // Extent is a postgres BOX string
    const bounds = computeBoundsForFeatureCollection(localDataset.geometry)
    const extent = `BOX(${bounds[0]} ${bounds[1]},${bounds[2]} ${bounds[3]})`

    const defaultVectorVizParams: VectorVizParams = {
        mode: 'fill',
        color: '#000000',
        width: 2,
    }

    return {
        datasetId: localDataset.mapId + localDataset.filename,
        id: localDataset.mapId + localDataset.filename,
        name: localDataset.filename,
        createdAt: localDataset.createdAt,
        dataRegion: null,
        minMaxesPerBand: null,
        maxZoom: 20,
        attributes: {},
        updatedAt: localDataset.createdAt,
        dimensionInfo: {
            dimensions: [],
            bandDimensions: [],
        },
        isPublic: false,
        thumbnailUrl: null,
        extent: extent,
        isDeleted: false,
        domain: 'WORKSPACE',
        type: 'vector',
        status: 'ready',
        className: 'local-dataset',
        source: 'local',
        minZoom: 0,
        vizParams: defaultVectorVizParams,
        vizType: 'vector',
        isVisible: true,
        selectedDimensions: {},
        isBeingEdited: false,
        cardIsHovered: false,
        opacity: 100,
        isNewVersionAvailable: false,
        selectedEarthEngineVisualizationIndex: -1,
        localDataset: localDataset,
    }
}

function removeLocalDataset(mapId: string, filename: string) {
    const localDatasets = loadLocalDatasets()
    const newLocalDatasets = localDatasets.filter(
        (d) => d.mapId !== mapId || d.filename !== filename
    )
    CacheManager.setItem(
        LOCAL_DATASETS_LOCAL_STORAGE_KEY,
        JSON.stringify(newLocalDatasets)
    )
}

export {
    loadLocalDatasets,
    loadLocalDatasetsForMap,
    lookupGeoJsonCrsAsync,
    convertLocalDatasetToDataset,
    reprojectGeoJson,
    addLocalDataset,
    removeLocalDataset,
}
