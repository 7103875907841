export const LOCAL_DATASETS_LOCAL_STORAGE_KEY = 'localDatasetsV2'
export const MAX_COMMENT_TITLE_LENGTH = 80
export const MAX_COMMENT_LENGTH = 1000

//////// Available data regions
// NOTE: If you change any of the values here, please make sure to also change them in
//       the `constants.ts` file on the frontend!
export const DEFAULT_DATA_REGION = 'us-central1'
// Where we've got tiler deployments
export const TILER_DEPLOYMENT_REGIONS = ['us-central1', 'europe-west3']
// Buckets might be "multi-regional". So for each of those multi-regional buckets, we
// need to know which region to use when we're trying to access them.
// More info on the multi-regional buckets here: https://cloud.google.com/storage/docs/locations#location-mr
// We are not supporting predefined dual-regions or any asian multi-regions as of now
export const GCP_MULTI_REGION_MAPPING = {
    eu: 'europe-west3',
    us: 'us-central1',
}
