import { useEffect, useState } from 'react'

type DragDropHandlers = {
    onDrop?: (file: File) => void
    onDragStart?: () => void
    onDragEnd?: () => void
    acceptedTypes?: string[]
}

export function useGlobalDragDrop({
    onDrop,
    onDragStart,
    onDragEnd,
    acceptedTypes = ['application/json', 'application/geo+json']
}: DragDropHandlers) {
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {

        const handleDrag = (e: DragEvent) => {
            e.preventDefault()
            e.stopPropagation()
        }

        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault()
            setIsDragging(true)
            onDragStart?.()
        }

        const handleDrop = (e: DragEvent) => {
            e.preventDefault()
            setIsDragging(false)
            const file = e.dataTransfer?.files[0]
            if (file && acceptedTypes.includes(file.type)) {
                onDrop?.(file)
            }
        }

        const handleDragEnd = (e: DragEvent) => {
            e.preventDefault()
            setIsDragging(false)
        }

        document.addEventListener('dragend', handleDragEnd)
        document.addEventListener('dragleave', handleDragEnd)
        document.addEventListener('dragenter', handleDragEnter)
        document.addEventListener('dragover', handleDrag)
        document.addEventListener('drop', handleDrop)

        return () => {
            document.removeEventListener('dragenter', handleDragEnter)
            document.removeEventListener('dragover', handleDrag)
            document.removeEventListener('drop', handleDrop)
            document.removeEventListener('dragend', handleDragEnd)
            document.removeEventListener('dragleave', handleDragEnd)
        }
    }, [onDrop, onDragStart, onDragEnd, acceptedTypes])

    return isDragging
}
