import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Collapse,
    Link,
} from '@mui/material'
import React, { useState } from 'react'
import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'

interface CopyVizParamsDialogProps {
    open: boolean
    onClose: () => void
    sourceDataset: Dataset
}

function CopyVizParamsDialog({
    open,
    onClose,
    sourceDataset,
}: CopyVizParamsDialogProps) {
    const { state, updateVizParams } = useMapContext()
    const [selectedDatasets, setSelectedDatasets] = useState<Set<string>>(
        new Set()
    )
    const [showBandSelection, setShowBandSelection] = useState(false)
    const [selectedBands, setSelectedBands] = useState<Set<string>>(new Set())

    // Reset states when dialog opens
    React.useEffect(() => {
        if (open) {
            setSelectedDatasets(new Set())
            setShowBandSelection(false)
            setSelectedBands(new Set())
        }
    }, [open])

    // Get compatible datasets from the current map
    const compatibleDatasets = state.current.datasets.filter((dataset) => {
        if (dataset.id === sourceDataset.id) return false
        if (dataset.type !== sourceDataset.type) return false

        // For raster datasets, check if they have the same bands
        if (sourceDataset.type === 'raster' && dataset.type === 'raster') {
            const sourceBands = new Set(sourceDataset.bands)
            const targetBands = new Set(dataset.bands)
            return (
                JSON.stringify([...sourceBands].sort()) ===
                JSON.stringify([...targetBands].sort())
            )
        }

        return true
    })

    const handleToggleAll = () => {
        if (selectedDatasets.size === compatibleDatasets.length) {
            setSelectedDatasets(new Set())
        } else {
            setSelectedDatasets(new Set(compatibleDatasets.map((d) => d.id)))
        }
    }

    const handleToggleDataset = (datasetId: string) => {
        const newSelected = new Set(selectedDatasets)
        if (newSelected.has(datasetId)) {
            newSelected.delete(datasetId)
        } else {
            newSelected.add(datasetId)
        }
        setSelectedDatasets(newSelected)
    }

    const handleToggleBand = (band: string) => {
        const newSelected = new Set(selectedBands)
        if (newSelected.has(band)) {
            newSelected.delete(band)
        } else {
            newSelected.add(band)
        }
        setSelectedBands(newSelected)
    }

    const handleApply = () => {
        selectedDatasets.forEach((datasetId) => {
            const dataset = compatibleDatasets.find((d) => d.id === datasetId)
            if (dataset) {
                let vizParams = { ...sourceDataset.vizParams }

                // If band selection is active and bands are selected,
                // only copy the parameters for selected bands
                if (
                    showBandSelection &&
                    selectedBands.size > 0 &&
                    vizParams.minMaxesPerBand
                ) {
                    const filteredMinMaxes = {}
                    Object.entries(vizParams.minMaxesPerBand).forEach(
                        ([band, values]) => {
                            if (selectedBands.has(band)) {
                                filteredMinMaxes[band] = values
                            }
                        }
                    )
                    vizParams.minMaxesPerBand = filteredMinMaxes
                }

                updateVizParams(dataset, vizParams, sourceDataset.vizType)
            }
        })
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Copy Visualization Parameters</DialogTitle>
            <DialogContent>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Select datasets to copy the visualization style to:
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={
                                            selectedDatasets.size ===
                                            compatibleDatasets.length
                                        }
                                        indeterminate={
                                            selectedDatasets.size > 0 &&
                                            selectedDatasets.size <
                                                compatibleDatasets.length
                                        }
                                        onChange={handleToggleAll}
                                    />
                                </TableCell>
                                <TableCell>Dataset Name</TableCell>
                                <TableCell>Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {compatibleDatasets.map((dataset) => (
                                <TableRow key={dataset.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedDatasets.has(
                                                dataset.id
                                            )}
                                            onChange={() =>
                                                handleToggleDataset(dataset.id)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{dataset.name}</TableCell>
                                    <TableCell>{dataset.type}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {compatibleDatasets.length === 0 && (
                    <Typography
                        variant="body2"
                        sx={{ mt: 2, textAlign: 'center' }}
                    >
                        No compatible datasets found in the current map
                    </Typography>
                )}

                {sourceDataset.type === 'raster' && sourceDataset.bands && (
                    <Box sx={{ mt: 2 }}>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() =>
                                setShowBandSelection(!showBandSelection)
                            }
                            sx={{ cursor: 'pointer' }}
                        >
                            {showBandSelection
                                ? 'Hide band selection'
                                : 'Select specific bands to copy'}
                        </Link>
                        <Collapse in={showBandSelection}>
                            <Box sx={{ mt: 1, ml: 2 }}>
                                {sourceDataset.bands.map((band) => (
                                    <FormControlLabel
                                        key={band}
                                        control={
                                            <Checkbox
                                                checked={selectedBands.has(
                                                    band
                                                )}
                                                onChange={() =>
                                                    handleToggleBand(band)
                                                }
                                            />
                                        }
                                        label={band}
                                    />
                                ))}
                            </Box>
                        </Collapse>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="outlined"
                    onClick={handleApply}
                    disabled={selectedDatasets.size === 0}
                >
                    Apply to Selected
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CopyVizParamsDialog
