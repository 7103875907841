import React from 'react'
import { Box, Button } from '@mui/material'
import { Dataset } from '../../types/dataset'
import { useMapContext } from '../../context/map/mapContext'
import VizParamsEditor from '../VizParamsEditor/VizParamsEditor'

type VisualizationStepProps = {
    datasetId: string
    onClose: () => void
}

function VisualizationStep({ datasetId, onClose }: VisualizationStepProps) {
    const { state } = useMapContext()
    const allDatasets = [...state.current.datasets, ...state.catalog]
    const dataset = allDatasets.find((d) => d.id === datasetId)

    if (!dataset) {
        return null
    }

    // Create a dummy flyTo function since we don't need it in the stepper
    const dummyFlyTo = (dataset: Dataset) => {}

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <VizParamsEditor
                dataset={dataset}
                flyToDatasetBounds={dummyFlyTo}
                hideControls={true}
            />
            <Button variant="outlined" onClick={onClose}>
                Close
            </Button>
        </Box>
    )
}

export default VisualizationStep
