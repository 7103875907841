import * as React from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import Stack from '@mui/material/Stack'
import SidebarStyle from './Sidebar.module.css'
import * as Sentry from '@sentry/react'
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { Dataset } from '../../types/dataset'
import VizParamsEditor from '../VizParamsEditor/VizParamsEditor'
import MapBox from './MapBox'
import { useMapContext } from '../../context/map/mapContext'
import Header from './Header'
import DatasetList from '../DatasetList/DatasetList'
import SearchBar from '../SearchBar/SearchBar'
import { Close, CenterFocusStrong } from '@mui/icons-material'
import {
    addLocalDataset,
    convertLocalDatasetToDataset,
    lookupGeoJsonCrsAsync,
    reprojectGeoJson,
} from '../../api/localDataset'
import AddDatasetStepper from '../AddDatasetStepper/AddDatasetStepper'
import { MapRef } from 'react-map-gl'
import { filterDatasetsInView } from '../../utils'
import { useGlobalDragDrop } from '../../hooks/useGlobalDragDrop'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'
import { LocalDataset } from 'types/localDataset'

type SidebarProps = {
    flyToDatasetBounds: (dataset: Dataset) => void
    maxHeight: string | null
    setCatalogOpen: (value: boolean) => void
    mapRef: React.RefObject<MapRef>
    filterDatasetsInView?: (
        bounds: [number, number, number, number]
    ) => Dataset[]
}

function Sidebar({
    flyToDatasetBounds,
    maxHeight,
    setCatalogOpen,
    mapRef,
}: SidebarProps) {
    const {
        state,
        dispatch,
        removeDatasetFromMap,
        addDatasetToMap,
        lastDatasetRef,
        changeMap,
    } = useMapContext()
    const [searchTerm, setSearchTerm] = useState('')
    const theme = useTheme()
    const [error, setError] = useState<string>(null)
    const [pastedDatasetUrl, setPastedDatasetUrl] = useState<string>('')

    const supabase = useSupabaseContext()

    const [datasetAddDialogOpen, setDatasetAddDialogOpen] =
        React.useState(false)
    const [filterToView, setFilterToView] = useState(false)

    let datasets = state.current.datasets
    const searchShouldShow = datasets.length > 1
    if (searchShouldShow && searchTerm != '') {
        datasets = datasets.filter((dataset) =>
            dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }
    if (searchShouldShow) {
        if (searchTerm !== '') {
            datasets = datasets.filter((dataset) =>
                dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }
    }

    // Filter datasets in view
    if (filterToView && mapRef.current) {
        const bounds = mapRef.current.getBounds()
        const viewportBounds: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ]
        datasets = filterDatasetsInView(datasets, viewportBounds)
    }

    // Expand the dataset list if the map is empty
    React.useEffect(() => {
        if (state.current && state.current.datasets.length === 0) {
            setCatalogOpen(true)
        }
    }, [state.current, setCatalogOpen])

    const handleFileDropAsync = (file: File) => {
        const reader = new FileReader()
        reader.onload = async (event: ProgressEvent<FileReader>) => {
            try {
                if (file.size > 10 * 1024 * 1024) {
                    const fileSizeInMb = file.size / (1024 * 1024)
                    throw new Error(
                        `File ${file.name} too large, the maximum file size we're supporting is ` +
                        `10mb. The file has ${fileSizeInMb.toFixed(2)}mb.`
                    )
                }

                const filename = file.name
                const mapId = state.current.metadata.id
                try {
                    const content = JSON.parse(event.target.result as string)
                    if (content.name) {
                        delete content.name
                    }
                    const crsProj4String = await lookupGeoJsonCrsAsync(supabase.session.access_token, content)
                    const reprojectedContent = reprojectGeoJson(content, crsProj4String)
                    const localDataset: LocalDataset = {
                        mapId: mapId,
                        filename: filename,
                        createdAt: new Date(file.lastModified).toISOString(),
                        geometry: reprojectedContent,
                    }
                    convertLocalDatasetToDataset(localDataset)
                    addLocalDataset(localDataset)
                } catch (error) {
                    Sentry.captureException(error)
                    throw new Error('The file might not be valid geojson.')
                }
                changeMap(mapId)
            } catch (error) {
                Sentry.captureException(error)
                setError(`Failed to add file ${file.name}, error: ${error.message}`)
            }
        }
        reader.readAsText(file)
    }

    const isDragging = useGlobalDragDrop({
        onDrop: handleFileDropAsync,
        acceptedTypes: ['application/json', 'application/geo+json']
    })

    const datasetBeingEdited = datasets.find((dataset) => dataset.isBeingEdited)

    React.useEffect(() => {
        const datasetBeingEdited = datasets.find((d) => d.isBeingEdited)
        if (datasetBeingEdited) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && datasetBeingEdited) {
                    dispatch({
                        type: 'TOGGLE_DATASET_EDITING',
                        datasetVersionId: datasetBeingEdited.id,
                    })
                }
            }
            window.addEventListener('keydown', handleKeyDown)
            return () => window.removeEventListener('keydown', handleKeyDown)
        }
    }, [datasets])

    // Values empirically determined
    const datasetListMaxHeight = maxHeight
        ? `calc(${maxHeight} - 295px)`
        : `calc(100vh - 421px)`

    const addButtonVariant =
        state.current.datasets.length > 0 ? 'outlined' : 'contained'

    const specialKeySymbol = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'
    React.useEffect(() => {
        const handlePaste = (event: ClipboardEvent) => {
            const target = event.target as HTMLElement
            if (
                target.tagName === 'INPUT' ||
                target.tagName === 'TEXTAREA' ||
                target.isContentEditable
            ) {
                return
            }
            event.clipboardData.items[0].getAsString((text) => {
                setPastedDatasetUrl(text.trim())
                setDatasetAddDialogOpen(true)
            })
        }
        window.addEventListener('paste', handlePaste)
        return () => window.removeEventListener('paste', handlePaste)
    }, [])

    const handleDatasetAddDialogClose = () => {
        setDatasetAddDialogOpen(false)
        setPastedDatasetUrl('')
    }

    return (
        <>
            {ReactDOM.createPortal(
                <Box
                    className={SidebarStyle.dragAndDropArea}
                    sx={{
                        backgroundColor: isDragging
                            ? 'rgb(255, 255, 255, 0.2)'
                            : 'transparent',
                    }}
                />,
                document.body
            )}
            <Dialog
                open={datasetAddDialogOpen}
                onClose={handleDatasetAddDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={'row'}>
                        <>Add new Dataset</>
                        <Box sx={{ flexGrow: '1' }} />
                        <IconButton onClick={handleDatasetAddDialogClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <AddDatasetStepper
                        initialUrl={pastedDatasetUrl}
                        onClose={handleDatasetAddDialogClose}
                    />
                </DialogContent>
            </Dialog>
            <Paper
                className={SidebarStyle.sidebar}
                sx={{
                    maxHeight: maxHeight,
                }}
            >
                <Stack direction="column" spacing={1}>
                    <Header />
                    <Divider />
                    <MapBox />
                    {searchShouldShow && (
                        <Box sx={{ padding: '0px 8px' }}>
                            <Stack direction="row" spacing={1}>
                                <SearchBar
                                    searchTerm={searchTerm}
                                    onSearchChange={setSearchTerm}
                                    placeholder="Search datasets"
                                    onSubmit={() => { }}
                                    showSearchIcon={true}
                                    disabled={false}
                                />
                                <Tooltip
                                    title={
                                        filterToView
                                            ? 'Toggle to show all datasets'
                                            : 'Toggle to only show datasets in current view'
                                    }
                                >
                                    <IconButton
                                        onClick={() =>
                                            setFilterToView(!filterToView)
                                        }
                                        color={
                                            filterToView
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        size="small"
                                    >
                                        <CenterFocusStrong />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    )}
                    <Divider />
                    <Box
                        sx={{
                            maxHeight: datasetListMaxHeight,
                            overflowY: 'auto',
                        }}
                    >
                        {datasetBeingEdited && (
                            <VizParamsEditor
                                dataset={datasetBeingEdited}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        )}
                        {!datasetBeingEdited &&
                            state.current.datasets.length > 0 && (
                                <div id="mapDatasets">
                                    <DatasetList
                                        datasets={datasets}
                                        flyToDatasetBounds={flyToDatasetBounds}
                                        showVizSettings={true}
                                        showSelector={false}
                                        showRemoveFromMap={true}
                                        showVisibility={true}
                                        disableNonActive={true}
                                        reorderable={true}
                                    />
                                    <div ref={lastDatasetRef} />
                                </div>
                            )}
                    </Box>
                    <Stack
                        direction={'column'}
                        spacing={1}
                        className={SidebarStyle.addButton}
                    >
                        <Button
                            variant={addButtonVariant}
                            fullWidth
                            onClick={() => setDatasetAddDialogOpen(true)}
                            id="addDatasetButton"
                        >
                            Add Dataset
                        </Button>
                        <Box className={SidebarStyle.addHelpText}>
                            <Typography variant={'body2'}>
                                Try {specialKeySymbol}+V or dragging a file onto
                                the map
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Snackbar
                    open={error !== null}
                    autoHideDuration={5000}
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{ width: '400px' }}
                >
                    <Alert
                        onClose={() => setError(null)}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                        key={error}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            </Paper>
        </>
    )
}

export default Sidebar
