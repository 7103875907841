import * as React from 'react'
import Box from '@mui/material/Box'
import { Map as MapGLMap } from 'react-map-gl'
import MapboxSatellite from '../../components/MapView/MapboxSatellite'
import logo_light from '../../assets/earthscale-logo-light.svg'
import ChangePasswordStyles from './ChangePassword.module.css'
import {
    Button,
    Divider,
    Input,
    Paper,
    Typography,
    useTheme,
} from '@mui/material'
import CacheManager from '../../context/cache'
import { useSupabaseContext } from '../../context/supabase/supabaseContext'

const startLocations = [
    {
        latitude: 38.6559,
        longitude: -101.9236,
        zoom: 12,
    },
    {
        latitude: 35.615,
        longitude: 139.653,
        zoom: 12,
    },
    {
        latitude: 46.44,
        longitude: 7.747,
        zoom: 12,
    },
    {
        latitude: 25.636,
        longitude: 10.077,
        zoom: 12,
    },
    {
        latitude: 64.46217212812809,
        longitude: 10.74625177968295,
        zoom: 12,
    },
    {
        latitude: 3.7440868431601615,
        longitude: -60.2883514844536,
        zoom: 12,
    },
    {
        latitude: 22.559,
        longitude: 32.314,
        zoom: 12,
    },
]

const randomStartLocation =
    startLocations[Math.floor(Math.random() * startLocations.length)]

function ChangePassword() {
    // Set page title
    React.useEffect(() => {
        document.title = 'Change Password - Earthscale'
    }, [])
    const supabase = useSupabaseContext()
    const mapRef = supabase.loginMapRef
    // Clear cache on first render
    React.useEffect(() => {
        CacheManager.invalidateCache()
    }, [])

    const theme = useTheme()

    // state for the two input fields
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [errorMessage, setErrorMessage] = React.useState('')

    // Set error message if passwords do not match or shorter than 8 characters
    const validatePassword = () => {
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match')
        } else if (password.length < 8) {
            setErrorMessage('Password must be at least 8 characters')
        } else {
            setErrorMessage('')
        }
    }
    React.useEffect(() => {
        setServerErrorMessage('')
        validatePassword()
    }, [password, confirmPassword])

    // on submit validate error from server
    const [serverErrorMessage, setServerErrorMessage] = React.useState('')
    const handleSubmit = async () => {
        if (errorMessage.length > 0) {
            return
        }
        const response = await supabase.client.auth.updateUser({
            password: password,
        })
        if (response.error) {
            setServerErrorMessage(response.error.message)
        } else {
            window.location.href = '/'
        }
    }

    // Move login map
    const distancePerSecond = 0.001
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!mapRef?.current) {
                return
            }
            const center = mapRef.current.getCenter()
            center.lng += distancePerSecond
            center.lat += distancePerSecond
            mapRef.current.easeTo({
                center,
                essential: true,
                duration: 1000,
                easing: (n) => n,
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [mapRef])

    return (
        <Box className={ChangePasswordStyles.changePasswordContainer}>
            <MapGLMap
                initialViewState={randomStartLocation}
                ref={mapRef}
                interactive={false}
                attributionControl={true}
                mapboxAccessToken={import.meta.env.EARTHSCALE_MAPBOX_TOKEN}
                logoPosition={'bottom-left'}
            >
                <MapboxSatellite />
            </MapGLMap>

            <Paper
                className={ChangePasswordStyles.changePasswordBox}
                sx={{
                    padding: '16px',
                }}
            >
                <img
                    src={logo_light as string}
                    alt="Logo"
                    className={ChangePasswordStyles.logo}
                />
                <Divider
                    sx={{
                        width: '100%',
                        color: theme.palette.primary.light,
                        marginBottom: '16px',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        width: '100%',
                    }}
                >
                    <Typography variant="h5" align={'center'}>
                        Change Password
                    </Typography>
                    <Input
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="New Password"
                        type="password"
                        error={errorMessage.length > 0}
                    />
                    <Input
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        type="password"
                        error={errorMessage.length > 0}
                    />
                    <Button
                        variant="contained"
                        disabled={errorMessage.length > 0}
                        onClick={handleSubmit}
                    >
                        Change
                    </Button>
                    {errorMessage.length > 0 && (
                        <Typography color="error">{errorMessage}</Typography>
                    )}

                    {serverErrorMessage.length > 0 && (
                        <Typography color="error">
                            {serverErrorMessage}
                        </Typography>
                    )}
                </Box>
            </Paper>
        </Box>
    )
}

export default ChangePassword
